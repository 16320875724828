import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogTitle, Typography } from "@material-ui/core";
import { RedButton } from "../../index";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(({ spacing }) => ({
	content: {
		position: "relative"
	},
	cancelButton: {
		marginRight: spacing(1)
	},
	confirmButton: {
		marginLeft: spacing(1)
	},
	warningText: {
		paddingTop: spacing(1),
		paddingBottom: spacing(2),
		paddingLeft: spacing(3),
		paddingRight: spacing(3),
		gravity: "center"
	},
	horizontalContent: {
		display: "flex",
		flexDirection: "row",
		padding: spacing(2),
		width: "100%",
		justifyContent: "center",
		boxSizing: "border-box"
	}
}));

type WarningDeleteDialogProps = {
	open: boolean;
	handleClose: (doDelete: boolean) => void;
};

const WarningDeleteDialog: React.FC<WarningDeleteDialogProps> = ({ open, handleClose }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
			<DialogTitle id="simple-dialog-title">Are you sure ?</DialogTitle>
			<Typography className={classes.warningText}>{t("cart.product_delete_warning")}</Typography>
			<div className={classes.horizontalContent}>
				<Button variant="outlined" className={classes.cancelButton} onClick={() => handleClose(false)}>
					{t("cart.cancel")}
				</Button>
				<RedButton className={classes.confirmButton} text={"Confirm"} onAction={() => handleClose(true)} />
			</div>
		</Dialog>
	);
};
export default React.memo(WarningDeleteDialog);
