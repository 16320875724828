import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Typography } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import Button from "@material-ui/core/Button";
import { Cache, Item } from "../../../core";
import { useTranslation } from "react-i18next";
import { CartItem } from "../../../types";

const useStyles = makeStyles(({ spacing }) => ({
	content: {
		display: "flex",
		flexDirection: "column",
		paddingTop: spacing(1),
		paddingBottom: spacing(1),
		width: "100%",
		maxHeight: "calc(var(--vh, 1vh) * 30)",
		boxSizing: "border-box"
	},
	horizontalContent: {
		display: "flex",
		flexDirection: "row",
		paddingLeft: spacing(1),
		paddingRight: spacing(1)
	},
	contentPackShot: {
		width: "50%"
	},
	productInfosContainer: {
		display: "flex",
		flexDirection: "column",
		paddingLeft: spacing(1),
		width: "50%"
	},
	smallInfosContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		paddingTop: spacing(1),
		paddingRight: spacing(1)
	},
	brand: {
		fontWeight: "bold",
		justifyContent: "flex-start"
	},
	price: {
		marginTop: spacing(2),
		padding: spacing(1),
		background: "linear-gradient(45deg, #FFDD00, #FFEA28)",
		width: "fit-content"
	},
	productCaption: {
		color: "#748187"
	},
	buttonContainer: {
		display: "flex",
		flexGrow: 1,
		flexDirection: "row"
	},
	button: {
		fontWeight: "bold",
		borderColor: "#D5DDE0",
		padding: spacing(1),
		alignSelf: "flex-end",
		flexGrow: 1
	}
}));

type CartNewSizeCellProps = {
	previousCartItem: CartItem;
	onItemEdited: (item: CartItem) => void;
	isLoading: boolean;
};

const CartNewSizeCell: React.FC<CartNewSizeCellProps> = ({ previousCartItem, onItemEdited, isLoading }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const product = Cache.getProductFromArticleId(previousCartItem.articleId);
	const item = product?.articles.filter((a) => "" + a.articleId === previousCartItem.articleId)[0] as Item;

	if (!(product && item)) {
		return null;
	}

	//DATAS
	const productName = product.description.productName;
	const { brand } = product;
	const pictureUrl = product.images[0].url;
	const sizeLabel = item.attribute.attributeValue;

	const cartItem = {
		articleId: "" + item.articleId,
		quantity: 1
	};

	return (
		<div className={classes.content}>
			<div className={classes.horizontalContent}>
				<img className={classes.contentPackShot} src={pictureUrl} alt={t("Product")} />
				<div className={classes.productInfosContainer}>
					<Typography className={classes.brand}>{brand}</Typography>
					<Typography variant="body2">{productName}</Typography>
					<div className={classes.smallInfosContainer}>
						<Typography variant="caption" className={classes.productCaption}>
							{t("cart.size")}: {sizeLabel}
						</Typography>
						<Typography variant="caption" className={classes.productCaption}>
							{t("cart.quantity")}: {previousCartItem.quantity}
						</Typography>
					</div>
					{isLoading ? (
						<div className={classes.buttonContainer}>
							<Button variant="outlined" className={classes.button}>
								<CircularProgress size={20} />
							</Button>
						</div>
					) : (
						<div className={classes.buttonContainer}>
							<Button
								variant="outlined"
								className={classes.button}
								startIcon={<CreateIcon />}
								onClick={() => onItemEdited(cartItem)}
							>
								{t("cart.reorderNewSize")}
							</Button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default CartNewSizeCell;
