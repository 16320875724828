import { FunctionComponent } from "react";
import classes from "./styles.module.scss";
import Item from "../../../../core/types/product/Item";
import { Stock } from "../../../../core/types/stock";
import { SizeLabel } from "../../../../components";

type ProductSizesProps = {
	items: Array<Item>;
	stocks: Array<Stock>;
	className?: string;
};

const ProductSizes: FunctionComponent<ProductSizesProps> = ({ items, stocks, className }) => {
	return (
		<div className={className + " " + classes.availableSize}>
			{items.map((item) => {
				const stock = stocks?.find((y) => "" + y.id === "" + item.articleId);
				return (
					<SizeLabel key={item.articleId} crossed={!stock || stock.qty <= 0} label={item.attribute.attributeValue} />
				);
			})}
		</div>
	);
};

export default ProductSizes;
