import { FunctionComponent } from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import BoxOpenIcon from "../../../../../assets/openbox.svg";

import classes from "./CartStateInPreparation.module.scss";

const CartStateInPreparation: FunctionComponent = () => {
	const { t } = useTranslation();

	return (
		<div className={classes.root}>
			<div className={classes.header}>
				<img className={classes.boxIcon} alt={t("QR Code")} src={BoxOpenIcon} />
			</div>
			<div className={classes.content}>
				<Typography className={classes.title}>{t("cart.in_preparation")}</Typography>
			</div>
		</div>
	);
};

export default CartStateInPreparation;
