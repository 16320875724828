import React, { FunctionComponent } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { createTheme } from "@material-ui/core/styles";
import { Theme, ThemeProvider } from "@material-ui/core";
import { Provider } from "react-redux";

import Main from "./pages/Main/Main";

import store from "./store";

const App: FunctionComponent = () => {
	const theme: Theme = createTheme({
		typography: {
			fontFamily: '"Roboto Condensed", "Roboto"'
		}
	});

	return (
		<Router>
			<Provider store={store}>
				<ThemeProvider theme={theme}>
					<Main />
				</ThemeProvider>
			</Provider>
		</Router>
	);
};

export default App;
