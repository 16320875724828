import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CreateIcon from "@material-ui/icons/Create";
import { Divider, ListItem, Typography } from "@material-ui/core";
import { YellowButton } from "../index";
import LightCartCell from "../CartCell/LightCartCell";
import { useTranslation } from "react-i18next";
import CartItem from "../../types/CartItem";

const useStyles = makeStyles(({ spacing }) => ({
	content: {
		display: "flex",
		flexDirection: "column",
		paddingTop: spacing(1),
		paddingBottom: spacing(1),
		width: "100%",
		height: "20%git",
		boxSizing: "border-box"
	},
	button_cancel: {
		flexGrow: 1,
		marginLeft: spacing(2),
		marginRight: spacing(2),
		fontWeight: "bold",
		borderColor: "#D5DDE0",
		padding: spacing(1)
	},
	warning: {
		display: "flex",
		justifyContent: "center",
		backgroundColor: "#E53935",
		marginLeft: spacing(2),
		marginRight: spacing(2),
		marginBottom: spacing(2)
	},
	validateButton: {
		marginLeft: spacing(2),
		marginRight: spacing(2),
		marginTop: spacing(2)
	},
	warningText: {
		color: "white",
		padding: spacing(1)
	},
	divider: {
		height: "1px",
		width: "100%",
		marginTop: spacing()
	},
	item: {
		width: "100%",
		display: "flex",
		flexDirection: "column"
	}
}));

type NotAvailableItemsProps = {
	cart: Array<CartItem>;
	onContinue: (doContinue: boolean) => void;
};

const NotAvailableItems: React.FC<NotAvailableItemsProps> = ({ cart, onContinue }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.content}>
			<div className={classes.warning}>
				<Typography className={classes.warningText}>{t("cart.product_not_available")}</Typography>
			</div>
			<div>
				{cart.map((item, index) => (
					<ListItem key={item.articleId} className={classes.item}>
						<LightCartCell articleId={item.articleId} />
						{index !== cart.length - 1 ? <Divider className={classes.divider} /> : null}
					</ListItem>
				))}
			</div>
			<Button
				variant="outlined"
				className={classes.button_cancel}
				startIcon={<CreateIcon />}
				onClick={() => onContinue(false)}
			>
				{t("cart.go_back_selection")}
			</Button>
			<YellowButton
				className={classes.validateButton}
				text={t("cart.validate_picking_anyway")}
				onAction={() => onContinue(true)}
			/>
		</div>
	);
};

export default React.memo(NotAvailableItems);
