import { FunctionComponent } from "react";
import { Paper, Typography } from "@material-ui/core";
import classes from "./styles.module.scss";
import { useTranslation } from "react-i18next";

type NotAvailableFooterProps = {
	reason?: "noStock" | "notShowroom";
	className?: string;
};

const NotAvailableFooter: FunctionComponent<NotAvailableFooterProps> = ({ reason, className }) => {
	const { t } = useTranslation();
	const text = reason === "notShowroom" ? "product.self_service" : "product.no_stock";
	return (
		<div className={className + " " + classes.footerExist}>
			<Paper className={classes.alreadyExist}>
				<Typography className={classes.text}>{t(text)}</Typography>
			</Paper>
		</div>
	);
};

export default NotAvailableFooter;
