import { useEffect, useState } from "react";
import PermissionState from "../types/PermissionState";

export default function useCameraPermission() {
	const [permissionState, setPermissionState] = useState(PermissionState.PROMPT);

	useEffect(() => {
		(async () => {
			const hasPermissions = !!(await navigator.mediaDevices.enumerateDevices()).filter(
				(d) => d.kind === "videoinput" && d.deviceId && d.label
			).length;
			if (!hasPermissions) {
				try {
					const stream = await navigator.mediaDevices.getUserMedia({
						video: true
					});
					stream.getTracks().forEach((x) => x.stop());
					setPermissionState(PermissionState.GRANTED);
				} catch (e) {
					setPermissionState(PermissionState.DENIED);
				}
			} else {
				setPermissionState(PermissionState.GRANTED);
			}
		})();
	}, []);

	return permissionState;
}
