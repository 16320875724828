//TODO: replace everywhere by this utils functions

export enum EventType {
	PageView = "page_view"
}

export const sendHitEvent = (eventType: EventType, data: Record<string, unknown>) => {
	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	if (!(window as any).gtag) {
		console.log(`should send event "${eventType}" with "${JSON.stringify(data)}"`);
		return;
	}

	if (!data.non_interaction) {
		data.non_interaction = true;
	}
	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	(window as any).gtag("event", eventType, data);
};

export const sendPageViewEvent = (page: string) => {
	return sendHitEvent(EventType.PageView, { page_location: page });
};
