import { FunctionComponent, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import ItemAndStock from "../../../core/types/ItemAndStock";

import classes from "./size-picker.module.scss";

type SizePickerProps = {
	initIndex: number;
	sizes: Array<ItemAndStock>;
	onSizeSelected: (size: ItemAndStock) => void;
};

const SizePicker: FunctionComponent<SizePickerProps> = ({ initIndex, sizes, onSizeSelected }) => {
	const [selected, setSelected] = useState<number>(initIndex);

	return (
		<div className={classes.container}>
			<Grid container spacing={3} direction="row" justify="space-evenly" alignItems="stretch">
				{sizes.map((it, index) => (
					<Grid item xs={4} key={it.item.articleId}>
						{it.stock.qty > 0 ? (
							<button
								className={`${classes.sizeBtn} ${index === selected ? classes.selected : ""}`}
								onClick={(event) => {
									event.stopPropagation();
									setSelected(index);
									onSizeSelected(it);
								}}
							>
								{it.item.attribute.attributeValue}
							</button>
						) : null}
						{it.stock.qty === 0 ? (
							<Typography className={`${classes.sizeBtn} ${classes.unavailable}`}>
								{it.item.attribute.attributeValue}
							</Typography>
						) : null}
					</Grid>
				))}
			</Grid>
		</div>
	);
};

export default SizePicker;
