import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import CustomCircularProgress from "../CustomCircularProgress";
import { NotificationHeader } from "..";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(({ spacing }) => ({
	content: {
		width: "100%",
		maxHeight: "calc(var(--vh, 1vh) * 50)",
		boxSizing: "border-box"
	},
	contentIsReady: {
		display: "flex",
		flexDirection: "column",
		paddingTop: spacing(1),
		paddingBottom: spacing(1),
		backgroundColor: "#01BE8A",
		boxSizing: "border-box"
	},
	contentIsPreparing: {
		display: "flex",
		flexDirection: "row",
		paddingTop: spacing(1),
		paddingBottom: spacing(1),
		backgroundColor: "#0082C3",
		alignItems: "center"
	},
	preparingTitle: {
		width: "70%",
		marginTop: spacing(1),
		marginBottom: spacing(1),
		marginLeft: spacing(3),
		marginRight: spacing(3),
		color: "white"
	},
	readyTitle: {
		marginTop: spacing(1),
		marginBottom: spacing(1),
		marginLeft: spacing(3),
		marginRight: spacing(3),
		color: "white"
	},
	progressContainer: {
		marginRight: spacing(3)
	},
	button: {
		marginLeft: spacing(3),
		marginRight: spacing(3),
		marginTop: spacing(1),
		marginBottom: spacing(1),
		background: "#008d5d",
		color: "white",
		fontWeight: "bold",
		borderRadius: 4,
		padding: "10px 20px 10px 20px",
		border: "none",
		outline: "none",
		boxSizing: "content-box"
	}
}));

type CardNotificationProps = {
	index: number;
	isCartReady: boolean;
	onChangeSize: () => void;
};

const CartNotification: React.FC<CardNotificationProps> = ({ index, isCartReady, onChangeSize }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.content}>
			<NotificationHeader index={index} />
			{isCartReady ? (
				<div className={classes.contentIsReady}>
					<Typography className={classes.readyTitle} variant={"h6"} align={"center"}>
						{t("cart.picking_area")}
					</Typography>
					<button className={classes.button} onClick={onChangeSize}>
						{t("cart.try_another_size")}
					</button>
				</div>
			) : (
				<div className={classes.contentIsPreparing}>
					<Typography className={classes.preparingTitle} variant={"h6"}>
						{t("cart.in_preparation")}
					</Typography>
					<div className={classes.progressContainer}>
						<CustomCircularProgress />
					</div>
				</div>
			)}
		</div>
	);
};

export default React.memo(CartNotification);
