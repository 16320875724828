import { FunctionComponent } from "react";
import classes from "./CartStateReady.module.scss";
import { Button, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CheckIcon from "@material-ui/icons/Check";
import { Cart } from "./../../../../../types";
import CartContainers from "./CartContainers";

type StateReadyProps = {
	cart: Cart;
	onChangeSize: () => void;
};

const CartStateReady: FunctionComponent<StateReadyProps> = ({ cart, onChangeSize }) => {
	const { t } = useTranslation();

	const itemsCount = Object.values(cart.items).reduce((v, c) => v + c.quantity, 0);
	const cartTime = new Date(cart.updatedAt).toLocaleTimeString([], {
		hour: "2-digit",
		minute: "2-digit"
	});

	return (
		<div className={classes.root}>
			<div className={classes.header}>
				<CheckIcon />
			</div>
			<div className={classes.content}>
				<Typography className={classes.time}>{cartTime}</Typography>
				<Typography className={classes.title}>{t("cart.picking_area", { count: itemsCount })}</Typography>
				<CartContainers containers={cart.containers} />
				<div className={classes.buttonContent}>
					<Button className={classes.button} onClick={onChangeSize}>
						{t("cart.try_another_size")}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default CartStateReady;
