import React from "react";
import { Stock } from "../../../core/types/stock";
import SelectStockFooter from "./SelectStockFooter";
import NotAvailableFooter from "./NotAvailableFooter";
import Item from "../../../core/types/product/Item";

type ProductFooterProps = {
	items: Array<Item>;
	stocks?: Array<Stock>;
	isNotShowroom: boolean;
	onValidate: () => void;
	className?: string;
};

const ProductFooter: React.FC<ProductFooterProps> = ({ items, stocks, isNotShowroom, onValidate, className }) => {
	return (
		<div className={className}>
			{!isNotShowroom && stocks && stocks.length > 0 ? (
				<SelectStockFooter items={items} stocks={stocks} onValidate={onValidate} />
			) : (
				<NotAvailableFooter reason={isNotShowroom ? "notShowroom" : "noStock"} />
			)}
		</div>
	);
};

export default React.memo(ProductFooter);
