import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(({ spacing }) => ({
	container: {
		maxWidth: "100%",
		display: "flex",
		flexDirection: "row",
		color: "#000",
		alignItems: "center"
	},
	title: {
		fontWeight: "bold",
		fontFamily: "300",
		fontSize: "16pt",
		padding: spacing()
	},
	divider: {
		height: "1px",
		width: "200px",
		backgroundColor: "#000"
	}
}));

type NotificationHeaderProps = {
	index: number;
};

const NotificationHeader: React.FC<NotificationHeaderProps> = ({ index }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.container}>
			<Typography className={classes.title}>{t("cart.notification_title", { index: index + 1 })}</Typography>
			<Divider className={classes.divider} />
		</div>
	);
};

export default React.memo(NotificationHeader);
