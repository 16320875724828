import { FunctionComponent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store";
import { RootState } from "src/store/states";
import CacheState from "src/store/states/Cache";

const useStyles = makeStyles(({ spacing }) => ({
	content: {
		display: "flex",
		flexDirection: "column",
		paddingTop: spacing(1),
		paddingBottom: spacing(1),
		width: "100%",
		maxHeight: "calc(var(--vh, 1vh) * 50)",
		boxSizing: "border-box"
	},
	horizontalContent: {
		display: "flex",
		flexDirection: "row",
		paddingLeft: spacing(1),
		paddingRight: spacing(1)
	},
	contentPackShot: {
		width: "30%"
	},
	productInfosContainer: {
		display: "flex",
		flexDirection: "column",
		paddingLeft: spacing(1)
	},
	productCaption: {
		color: "#748187"
	}
}));

type LightProductCellProps = {
	articleId: string;
};

const LightCartCell: FunctionComponent<LightProductCellProps> = ({ articleId }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const cache = useAppSelector<RootState, CacheState>(({ cache }) => cache);
	const product = cache.products[cache.items[articleId]];
	const [article] = product.articles.filter((a) => "" + a.articleId === articleId);

	const pictureUrl = product.images[0].url;
	const sizeLabel = article.attribute.attributeValue;
	const productName = product.description.productName;

	return (
		<div className={classes.content}>
			<div className={classes.horizontalContent}>
				<img className={classes.contentPackShot} src={pictureUrl} alt={t("Product")} />
				<div className={classes.productInfosContainer}>
					<Typography variant="body2">{productName}</Typography>
					<Typography variant="caption" className={classes.productCaption}>
						{t("cart.size")}: {sizeLabel}
					</Typography>
				</div>
			</div>
		</div>
	);
};

export default LightCartCell;
