import axios, { AxiosPromise } from "axios";
import ApiResponse from "../types/FullProduct";
import { Order, OrderLine, OrderStatus } from "../types/order";
import { LastOrders } from "../types/order/LastOrder";
import { Stock } from "../types/stock";

// TODO: refactor
class B4FApi {
	/**
	 * Get product with reviews
	 * @param productId product id
	 * @param locale locale (ex : ca_en)
	 * @returns
	 */
	static getProduct(productId: string, locale: string): AxiosPromise<ApiResponse | undefined> {
		return axios({
			method: "get",
			url: `${process.env.REACT_APP_BO_ENDPOINT}/products/${productId}`,
			headers: {
				"x-api-key": `${process.env.REACT_APP_BO_API_KEY}`,
				"Accept-Language": locale
			}
		});
	}

	/**
	 *
	 * @param orderId
	 */
	static getOrderStatus(orderId: string): Promise<OrderStatus> {
		return axios
			.get<OrderStatus>(`${process.env.REACT_APP_BO_ENDPOINT}/orders/${orderId}/status`, {
				headers: {
					"Content-Type": "application/json",
					"x-api-key": `${process.env.REACT_APP_BO_API_KEY}`
				}
			})
			.then((res) => res.data);
	}

	/**
	 * Create an order to the B4F
	 * @param surname string
	 * @param items item list
	 */
	static createOrder(surname: string, items: Array<OrderLine>): AxiosPromise<Order | undefined> {
		return axios({
			method: "post",
			url: `${process.env.REACT_APP_BO_ENDPOINT}/orders`,
			headers: {
				"Content-Type": "application/json",
				"x-api-key": `${process.env.REACT_APP_BO_API_KEY}`
			},
			data: JSON.stringify({ surname, items })
		});
	}

	/**
	 * Get stock for an item
	 * @param itemIds
	 * @returns
	 */
	static getStock(itemIds: string[]): AxiosPromise<Array<Stock> | undefined> {
		return axios({
			method: "get",
			url: `${process.env.REACT_APP_BO_ENDPOINT}/stocks?items=[${itemIds.join(",")}]`,
			headers: {
				"Content-Type": "application/json",
				"x-api-key": `${process.env.REACT_APP_BO_API_KEY}`
			}
		});
	}

	static getLastOrders(): AxiosPromise<LastOrders | undefined> {
		return axios({
			method: "get",
			url: `${process.env.REACT_APP_BO_ENDPOINT}/orders/last-orders`,
			headers: {
				"Content-Type": "application/json",
				"x-api-key": `${process.env.REACT_APP_BO_API_KEY}`
			}
		});
	}
}

export default B4FApi;
