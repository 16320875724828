import { FunctionComponent } from "react";
import { Button, Typography } from "@material-ui/core";
import FidIcon from "../../assets/logo_fid.png";
import { useTranslation } from "react-i18next";
import classes from "./styles.module.scss";
import LightBulb from "@material-ui/icons/EmojiObjects";

type MembershipProps = {
	onSubscribe: () => void;
};

const Membership: FunctionComponent<MembershipProps> = ({ onSubscribe }) => {
	const { t } = useTranslation();

	return (
		<div className={classes.container}>
			<div className={classes.header}>
				<LightBulb className={classes.bulb} />
			</div>
			<img src={FidIcon} className={classes.icon} alt="Decathlon" />
			<div className={classes.subscribe}>
				<Typography className={classes.title}>{t("cart.new_to_decathlon")}</Typography>
				<Button className={classes.button} onClick={onSubscribe}>
					{t("cart.become_a_member")}
				</Button>
			</div>
		</div>
	);
};

export default Membership;
