import { FunctionComponent } from "react";
import classes from "./styles.module.scss";
import { Grid, Typography } from "@material-ui/core";
import NoProductIcon from "../../../../assets/tutorial_product.svg";
import { GradientButton } from "../../../../components";
import { useTranslation } from "react-i18next";

type EmptyCartProps = {
	onScan: () => void;
};

const EmptyCart: FunctionComponent<EmptyCartProps> = ({ onScan }) => {
	const { t } = useTranslation();

	return (
		<Grid item container direction="column" justifyContent="space-between" alignItems="center" className={classes.root}>
			<Grid item>
				<Typography className={classes.subtitle} variant={"h4"} align={"center"}>
					{t("cart.empty_title")}
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant={"h6"} align={"center"}>
					{t("cart.empty_subtitle")}
				</Typography>
			</Grid>
			<Grid item>
				<img className={classes.image} src={NoProductIcon} alt={"No product"} />
			</Grid>
			<Grid item>
				<GradientButton
					className={classes.scanButtonNoProducts}
					text={t("cart.scan_button_alt")}
					onAction={() => onScan()}
				/>
			</Grid>
		</Grid>
	);
};

export default EmptyCart;
