import { Item } from "./../types/product";
import B4FApi from "./../apis/B4FApi";
import { Stock } from "../types/stock";

class StockRepository {
	/**
	 * Get stock for an item list
	 * @param itemIds item list
	 * @returns
	 */
	static getStock(items: string[]): Promise<Array<Stock> | undefined> {
		return B4FApi.getStock(items)
			.then((response) => response.data)
			.catch((error) => Promise.reject(error.response.data));
	}

	static mock(items: Array<Item>): Promise<Array<Stock> | undefined> {
		const stocks: Array<Stock> = [];
		items.forEach((item) => {
			stocks.push({
				id: item.articleId,
				qty: Math.floor(Math.random() * (3 - 0 + 1) + 0)
			});
		});

		return new Promise(function (resolve) {
			setTimeout(() => {
				resolve(stocks);
			}, 2000);
		});
	}
}

export default StockRepository;
