/* eslint-disable @typescript-eslint/no-explicit-any */
import { AutoStoreState, Cart as CartType } from "../../types";
import B4FApi from "./../apis/B4FApi";
import { Order, OrderStatus } from "../types/order";

class OrderRepository {
	/**
	 * Create an order in B4F
	 * @param surname
	 * @param cartItems
	 * @returns
	 */
	static createOrder(surname: string, cart: CartType): Promise<Order | undefined> {
		const items: any[] = []; //TODO: type
		Object.entries(cart.items).forEach(([articleId, item], i) => {
			items.push({
				line_num: i,
				prod_name: articleId,
				quantity: item.quantity
			});
		});

		return B4FApi.createOrder(surname, items)
			.then((response) => response.data)
			.catch((error) => Promise.reject(error));
	}

	/**
	 * Get order status
	 * @param orderId
	 * @returns
	 */
	static getOrderState(orderId: string): Promise<OrderStatus> {
		return B4FApi.getOrderStatus(orderId).catch(() => {
			return {
				status: AutoStoreState.IN_PROGRESS,
				cntnr_name: []
			};
		});
	}

	static getLastOrders() {
		return B4FApi.getLastOrders()
			.then(({ data: allOrders }) => {
				return (allOrders || [])
					.map((order) => order?.cntnr_name)
					.reduce((p, a) => p.concat(...a), [])
					.filter((e, i, a) => a.indexOf(e) === i);
			})
			.catch((error) => {
				console.error("error while fetching last orders", error);
			});
	}
}

export default OrderRepository;
