import { useState } from "react";
import { useTranslation } from "react-i18next";

import OrderRepository from "src/core/repositories/OrderRepository";
import { useInterval } from "src/hooks";

import classes from "./last-orders.module.scss";

const LastOrdersPage = () => {
	const [lastOrders, setLastOrders] = useState<string[]>([]);

	const { t } = useTranslation();

	useInterval(
		() => {
			(async () => {
				try {
					const lastOrdersData = await OrderRepository.getLastOrders();
					setLastOrders(lastOrdersData || []);
				} catch (e) {
					console.error("error while fetching last orders", e);
				}
			})();
		},
		30 * 1000,
		true
	);

	return (
		<div className={classes.pageWrapper}>
			<h1 className={classes.pageTitle}>{t("lastOrders.title")}</h1>
			<div className={classes.ordersWrapper}>
				{lastOrders
					?.slice(0, 30)
					.sort((a, b) => +a - +b)
					.map((order) => (
						<p key={order}>{order}</p>
					))}
			</div>
		</div>
	);
};

export default LastOrdersPage;
