import classes from "./styles.module.scss";
import WelcomeTitle from "../../../../assets/welcome_title.png";
import DecathlonIcon from "../../../../assets/decathlon.svg";

const SlideOne: React.FC = () => {
	return (
		<div className={classes.slide}>
			<img className={classes.icon_big} src={WelcomeTitle} alt="Welcome" />
			<img className={classes.icon} src={DecathlonIcon} alt="Decathlon" />
		</div>
	);
};

export default SlideOne;
