import React from "react";
import { Divider } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import Description from "../../../../core/types/product/Description";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import classes from "./technical-information.module.scss";

type TechnicalInformationsProps = {
	description: Description;
	showProductInfos: () => void;
	showAdvantages: () => void;
	className?: string;
};

const TechnicalInformations: React.FC<TechnicalInformationsProps> = ({
	description,
	showProductInfos,
	showAdvantages
}) => {
	const { t } = useTranslation();
	const informationConcept = description.informationConcept;
	const benefits = description.benefits;

	if (informationConcept != null) {
		const productInfos = informationConcept.technicalInformation;
		return (
			<div className={classes.actions}>
				{productInfos && (
					<button className={classes.action} onClick={showProductInfos}>
						{t("product.technical_informations")}
						<ChevronRightIcon className={classes.chevron} />
					</button>
				)}
				{productInfos && benefits ? <Divider className={classes.divider} /> : null}
				{benefits ? (
					<button className={classes.action} onClick={showAdvantages}>
						{t("product.benefits")}
						<ChevronRightIcon className={classes.chevron} />
					</button>
				) : null}
			</div>
		);
	} else {
		return (
			<div className={classes.actions}>
				{benefits ? (
					<button className={classes.action} onClick={showAdvantages}>
						{t("product.benefits")} <ChevronRightIcon className={classes.chevron} />
					</button>
				) : null}
			</div>
		);
	}
};

export default React.memo(TechnicalInformations);
