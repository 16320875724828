import { Cart as CartType, CartItem as CartItemType, CartState, CartState as CartStateEnum } from "../types";
import store from "../store";
import { CartsActions } from "../store/reducers/carts";

class Cart {
	static getCarts(): CartType[] {
		const { carts } = store.getState();

		return Object.values(carts.all);
	}

	static getPreviousCarts(): CartType[] {
		return this.getCarts().filter((c) => [CartState.CREATED].indexOf(c.state) === -1);
	}

	static createCart() {
		store.dispatch({ type: CartsActions.CREATE_CART });
	}

	static getCart(cartId?: string): CartType {
		const { carts } = store.getState();

		if (!cartId) {
			return carts.all[carts.currentId];
		}

		return carts.all[cartId];
	}

	static deleteCart(cartId: string) {
		const cart = this.getCart();
		// Current cart
		if (cart.id === cartId) {
			store.dispatch({ type: CartsActions.CREATE_CART });
		}
		store.dispatch({ type: CartsActions.DELETE_CART, payload: { id: cartId } });
	}

	static updateCart(data: Partial<CartType>, cartId?: string) {
		const cart = this.getCart(cartId);
		store.dispatch({
			type: CartsActions.SET_CART,
			payload: {
				id: cart.id,
				...data
			}
		});
	}

	// TODO: it should be deleted, I think it's not used anymore
	static setState(newState: CartStateEnum, cartId?: string) {
		const { carts } = store.getState();
		const cart = this.getCart(cartId);
		store.dispatch({
			type: CartsActions.SET_CART_STATE,
			payload: {
				id: cart.id,
				state: newState
			}
		});

		if (cart.id === carts.currentId && newState !== CartState.CREATED) {
			store.dispatch({ type: CartsActions.CREATE_CART });
		}
	}

	static addItem(item: CartItemType) {
		store.dispatch({ type: CartsActions.ADD_ITEM, payload: item });
	}

	static deleteItem(item: CartItemType) {
		this.deleteItems([item]);
	}

	static deleteItems(items: Array<Partial<CartItemType>>) {
		store.dispatch({ type: CartsActions.DELETE_ITEMS, payload: items });
	}

	static setItem(item: CartItemType) {
		store.dispatch({ type: CartsActions.SET_ITEM, payload: item });
	}

	static replaceItem(oldItem: CartItemType, item: CartItemType) {
		this.deleteItem(oldItem);
		this.addItem(item);
	}

	static getItems(cartId?: string): CartItemType[] {
		const cart = this.getCart(cartId);
		return Object.values(cart.items);
	}
}

export default Cart;
