import { useEffect, useRef } from "react";

export default function useInterval(callback: () => void, delay: number | null, callAtStart = false) {
	const savedCallback = useRef<() => void>();

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	// Set up the interval.
	useEffect(() => {
		function tick() {
			savedCallback.current && savedCallback.current();
		}

		if (delay !== null) {
			const id = window.setInterval(tick, delay);
			if (callAtStart) {
				tick();
			}
			return () => clearInterval(id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [delay]);
}
