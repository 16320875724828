import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(() => ({
	content: {
		position: "relative",
		width: 40,
		height: 40
	},
	top: {
		color: "#BEDEEF",
		animationDuration: "550ms",
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%"
	},
	bottom: {
		color: "#002C42",
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%"
	},
	circle: {
		strokeLinecap: "round"
	}
}));

const CustomCircularProgress: React.FC = () => {
	const classes = useStyles();

	return (
		<div className={classes.content}>
			<CircularProgress variant="determinate" className={classes.bottom} thickness={2} value={100} />
			<CircularProgress
				variant="indeterminate"
				disableShrink
				className={classes.top}
				classes={{
					circle: classes.circle
				}}
				thickness={2}
			/>
		</div>
	);
};
export default React.memo(CustomCircularProgress);
