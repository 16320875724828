import { createAction, createReducer } from "@reduxjs/toolkit";

import { UiState } from "../states";

const initialState: UiState = {
	cartTooltipShown: true
};

export enum UiActions {
	SET = "ui/set"
}

const set = createAction<Partial<UiState>>(UiActions.SET);

export default createReducer(initialState, (builder) => {
	builder.addCase(set, (state, { payload }) => {
		Object.assign(state, payload);
	});
});
