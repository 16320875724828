import React from "react";
import { useTranslation } from "react-i18next";
import Item from "../../../../core/types/product/Item";
import { Stock } from "../../../../core/types/stock";
import { YellowButton } from "../../../../components";
import ProductSizes from "../ProductSizes";

import classes from "./styles.module.scss";

type SelectStockFooterProps = {
	items: Array<Item>;
	stocks: Array<Stock>;
	onValidate: () => void;
	className?: string;
};

const SelectStockFooter: React.FC<SelectStockFooterProps> = ({ items, stocks, onValidate }) => {
	const { t } = useTranslation();
	return (
		<div className={classes.footer}>
			<button onClick={onValidate} className={classes.sizesBtn}>
				<ProductSizes items={items} stocks={stocks} />
			</button>
			<YellowButton text={t("product.select")} onAction={onValidate} />
		</div>
	);
};

export default React.memo(SelectStockFooter);
