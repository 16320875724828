import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import classes from "./styles.module.scss";
import Placeholder from "./decathlon_grey.svg";

const NoImagePlaceholder: FunctionComponent = () => {
	const { t } = useTranslation();

	return (
		<div className={classes.root}>
			<img className={classes.placeholder} src={Placeholder} alt={t("no_image")} />
		</div>
	);
};

export default NoImagePlaceholder;
