import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
	container: {
		maxWidth: "calc(var(--vw, 1vw) * 100)",
		display: "flex",
		flexDirection: "column",
		padding: 10,
		color: "#000"
	},
	title: {
		width: "100%",
		fontWeight: "bold",
		fontFamily: "Roboto Condensed"
	}
}));

const NewSizeHeader: React.FC = () => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.container}>
			<Typography className={classes.title} variant={"h4"} align={"center"}>
				{t("cart.try_another_size_header")}
			</Typography>
		</div>
	);
};

export default React.memo(NewSizeHeader);
