import { makeStyles } from "@material-ui/core/styles";
import { Divider, Typography } from "@material-ui/core";
import { TechnicalInformation } from "../../core";

const useStyles = makeStyles(({ spacing }) => ({
	container: {
		display: "flex",
		flexDirection: "column",
		width: "90%",
		padding: spacing(2)
	},
	title: {
		fontFamily: "500",
		fontWeight: "bold",
		fontSize: "11pt"
	},
	value: {
		fontFamily: "400",
		fontSize: "9pt",
		width: "100%"
	},
	info: {
		fontFamily: "500",
		fontWeight: "bold",
		fontSize: "13pt"
	},
	divider: {
		margin: spacing()
	},
	close: {
		marginLeft: "auto",
		alignSelf: "right"
	}
}));

type ProductInfosProps = {
	infos: TechnicalInformation[];
};

const ProductInfos: React.FC<ProductInfosProps> = ({ infos }) => {
	const classes = useStyles();

	return (
		<div className={classes.container}>
			{infos.map((info, i) => {
				const values = info.description.split("\n");
				return (
					<div key={`info-${i}`}>
						<Typography className={classes.title}>{info.name}</Typography>
						{values.map((value, i) => (
							<Typography className={classes.value} key={`value-${i}`}>
								{value}
							</Typography>
						))}
						<Divider className={classes.divider} />
					</div>
				);
			})}
		</div>
	);
};

export default ProductInfos;
