import B4FApi from "./../apis/B4FApi";
import { FullProduct } from "../types/product";

class ProductRepository {
	/**
	 * Get product with reviews from B4F
	 * @param productId product id
	 * @param locale locale (ex : ca_en)
	 * @returns
	 */
	static getProduct(productId: string, locale: string): Promise<FullProduct | undefined> {
		return B4FApi.getProduct(productId, locale).then((response) => response.data);
	}
}

export default ProductRepository;
