import React, { useEffect, useState } from "react";
import classes from "./styles.module.scss";
import { Add, Remove } from "@material-ui/icons";
import { IconButton, Typography } from "@material-ui/core";

type QuantityPickerProps = {
	className: string;
	initQuantity: number;
	maxQuantity: number;
	onQuantitySelected: (quantity: number) => void;
};

const QuantityPicker: React.FC<QuantityPickerProps> = ({
	className,
	initQuantity,
	maxQuantity,
	onQuantitySelected
}) => {
	const [quantity, setQuantity] = useState<number>(initQuantity);

	useEffect(() => {
		if (initQuantity) {
			setQuantity(initQuantity);
		}
	}, [initQuantity]);

	useEffect(() => {
		if (quantity) {
			onQuantitySelected(quantity);
		}
	}, [quantity]);

	const decrement = () => {
		if (quantity > 1) {
			setQuantity(quantity - 1);
		}
	};

	const increment = () => {
		if (quantity < maxQuantity) {
			setQuantity(quantity + 1);
		}
	};

	return (
		<div className={className}>
			<div className={classes.container}>
				<IconButton
					className={classes.action}
					disabled={quantity === 1}
					onClick={(event) => {
						event.stopPropagation();
						decrement();
					}}
				>
					<Remove />
				</IconButton>
				<Typography className={classes.quantity}>{quantity}</Typography>
				<IconButton
					className={classes.action}
					disabled={quantity === maxQuantity}
					onClick={(event) => {
						event.stopPropagation();
						increment();
					}}
				>
					<Add />
				</IconButton>
			</div>
		</div>
	);
};

export default QuantityPicker;
