import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { YellowButton } from "../../components";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(({ spacing }) => ({
	container: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		padding: spacing(2)
	},
	catchline: {
		fontSize: "16pt",
		fontWeight: "bold",
		transform: "skewY(-10deg)"
	},
	explanation: {
		width: "100%",
		textAlign: "center",
		padding: spacing(0, 1)
	},
	button: {
		marginBottom: spacing(3),
		marginTop: spacing(3)
	},
	close: {
		marginLeft: "auto",
		alignSelf: "right"
	}
}));

type CartConfirmedProps = {
	onConfirmed: () => void;
	onClose: () => void;
};

const CartConfirmed: React.FC<CartConfirmedProps> = ({ onConfirmed }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.container}>
			<Typography className={classes.catchline}>{t("cart.confirm.your_selection")}</Typography>
			<Typography className={classes.catchline}>{t("cart.confirm.will_be_available2")}</Typography>
			<Typography className={classes.catchline}>{t("cart.confirm.in_few_min")}</Typography>
			<YellowButton className={classes.button} text={"OK"} onAction={() => onConfirmed()} />
			<Typography className={classes.explanation}>{t("cart.confirm.just_go")}</Typography>
			<Typography className={classes.explanation}>{t("cart.confirm.to_the_pickup_area")}</Typography>
		</div>
	);
};

export default React.memo(CartConfirmed);
