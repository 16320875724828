import { Fragment, FunctionComponent, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Tooltip, Typography, Zoom } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store";
import { Cart, CartItem } from "../../../types";
import { Ui } from "../../../core";
import Price from "../../Price";
import NoImagePlaceholder from "../../NoImagePlaceholder";
import { RootState } from "src/store/states";

const useStyles = makeStyles(({ spacing }) => ({
	content: {
		display: "flex",
		flexDirection: "column",
		paddingTop: spacing(1),
		paddingBottom: spacing(1),
		width: "100%",
		maxHeight: "calc(var(--vh, 1vh) * 50)",
		boxSizing: "border-box"
	},
	horizontalContent: {
		display: "flex",
		flexDirection: "row",
		paddingLeft: spacing(1),
		paddingRight: spacing(1)
	},
	contentPackShot: {
		width: "50%",
		border: "none",
		padding: 0,
		"& > *": {
			width: "100%",
			height: "100%",
			objectFit: "cover"
		}
	},
	productInfosContainer: {
		display: "flex",
		flexDirection: "column",
		paddingLeft: spacing(1)
	},
	smallInfosContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		paddingTop: spacing(1),
		paddingRight: spacing(1)
	},
	brand: {
		fontWeight: "bold",
		justifyContent: "flex-start"
	},
	productCaption: {
		color: "#748187"
	},
	buttonContainer: {
		display: "flex",
		flexDirection: "row",
		marginTop: spacing(2)
	},
	button: {
		flexGrow: 1,
		margin: spacing(1),
		fontWeight: "bold",
		borderColor: "#D5DDE0",
		padding: spacing(1)
	},
	price: {
		marginTop: spacing(2)
	}
}));

const useStylesTooltip = makeStyles(() => ({
	arrow: {
		color: "#0082C3"
	},
	tooltip: {
		backgroundColor: "#0082C3"
	}
}));

type ProductCellProps = {
	cart: Cart;
	articleId: string;
	onItemDeleted: (item: CartItem) => void;
	onItemEdited: (item: CartItem) => void;
	onItemClicked: (item: CartItem) => void;
	isEditing?: boolean;
};

const CartCell: FunctionComponent<ProductCellProps> = (props) => {
	const classes = useStyles();
	const classesTooltip = useStylesTooltip();

	const { cache, ui } = useAppSelector<RootState, RootState>((stores) => stores);

	const { cart, articleId, onItemDeleted, onItemEdited, onItemClicked, isEditing } = props;

	const cartItem = cart.items[articleId];
	const product = cache.products?.[cache.items[articleId]];
	const [article] = product.articles.filter((a) => "" + a.articleId === "" + articleId);

	const productName = product.description.productName;
	const brand = product.brand;
	const pictureUrl = product.images[0]?.url;
	const sizeLabel = article.attribute.attributeValue;
	const price = product.priceForFront?.finalPrice;
	const { quantity } = cartItem;

	const { t } = useTranslation();

	useEffect(() => {
		const hideTooltip = setTimeout(() => Ui.hideCartTooltip(), 5000);
		return () => clearTimeout(hideTooltip);
	}, []);

	return (
		<div className={classes.content}>
			<div className={classes.horizontalContent}>
				<Tooltip
					classes={classesTooltip}
					open={ui.cartTooltipShown}
					title={<Fragment>{"Click here to add another size"}</Fragment>}
					TransitionComponent={Zoom}
					arrow
					leaveDelay={300}
				>
					<button onClick={() => onItemClicked(cartItem)} className={classes.contentPackShot}>
						{pictureUrl ? <img src={pictureUrl + "?f=200x200"} alt={t("Product")} /> : <NoImagePlaceholder />}
					</button>
				</Tooltip>
				<div className={classes.productInfosContainer}>
					<Typography className={classes.brand}>{brand}</Typography>
					<Typography variant="body2">{productName}</Typography>
					<div className={classes.smallInfosContainer}>
						<Typography variant="caption" className={classes.productCaption}>
							{t("cart.size")}: {sizeLabel}
						</Typography>
						<Typography variant="caption" className={classes.productCaption}>
							{t("cart.quantity")}: {quantity}
						</Typography>
					</div>
					<Price className={classes.price} value={price} />
				</div>
			</div>
			<div className={classes.buttonContainer}>
				<Button variant="outlined" className={classes.button} onClick={() => onItemDeleted(cartItem)}>
					{t("cart.delete")}
				</Button>
				{isEditing ? (
					<Button variant="outlined" className={classes.button}>
						<CircularProgress size={20} />
					</Button>
				) : (
					<Button
						variant="outlined"
						className={classes.button}
						startIcon={<CreateIcon />}
						onClick={() => onItemEdited(cartItem)}
					>
						{t("cart.edit")}
					</Button>
				)}
			</div>
		</div>
	);
};

export default CartCell;
