import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(({ spacing }) => ({
	content: {},
	text: {
		marginLeft: spacing(1),
		fontSize: "10pt"
	},
	textCrossed: {
		marginLeft: spacing(1),
		fontSize: "10pt",
		textDecorationLine: "line-through",
		color: "#A3A3A3"
	}
}));

type SizeLabelProps = {
	label: string;
	crossed: boolean;
};

const SizeLabel: React.FC<SizeLabelProps> = ({ label, crossed }) => {
	const classes = useStyles();

	return (
		<div className={classes.content}>
			<Typography className={crossed ? classes.textCrossed : classes.text}>{label}</Typography>
		</div>
	);
};

export default React.memo(SizeLabel);
