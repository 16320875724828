import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { CartNewSizeCell, GlobalHeader } from "../../components";
import { Divider, List, ListItem } from "@material-ui/core";
import { ChooseSizeAndQuantity } from "../../dialogs";
import { Cache, Cart, ProductType, Stock } from "../../core";
import NewSizeHeader from "../../components/Headers/NewSizeHeader";
import StockRepository from "../../core/repositories/StockRepository";
import { CartItem } from "../../types";
import Events from "../../core/repositories/Events";

const useStyles = makeStyles(({ spacing }) => ({
	container: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		boxSizing: "border-box"
	},
	list: {
		width: "100%",
		display: "flex",
		flexDirection: "column"
	},
	item: {
		width: "100%",
		display: "flex",
		flexDirection: "column"
	},
	divider: {
		height: "1px",
		width: "100%",
		marginTop: spacing()
	},
	header: {
		alignSelf: "center"
	}
}));

type EditSizeProps = {
	onClose: () => void;
};

const EditSize: FunctionComponent<EditSizeProps> = ({ onClose }) => {
	const classes = useStyles();

	const [showEdit, setShowEdit] = useState<boolean>(false);
	const [editingItem, setEditingItem] = useState<CartItem | undefined>();
	const [stocks, setStocks] = useState<Array<Stock> | undefined>();
	const [isLoading, setIsLoading] = useState<string | undefined>(undefined);

	const { id: cartId } = useParams();

	const { t } = useTranslation();

	const cart = Cart.getCart(cartId);
	const cartItems = Object.values(cart.items);

	useEffect(() => {
		(async () => {
			setStocks(undefined);
			if (editingItem) {
				setIsLoading(editingItem.articleId);
				try {
					const product = getProduct(editingItem);
					const articlesId = product?.articles.map((a) => "" + a.articleId) || [];
					const stocks = await StockRepository.getStock(articlesId);
					setStocks(stocks);
				} catch {
					console.error("error while getting stok information");
				}
				setIsLoading(undefined);
			}
		})();
	}, [editingItem]);

	const getProduct = (cartItem: CartItem): ProductType | null => {
		return Cache.getProductFromArticleId(cartItem.articleId);
	};

	return (
		<div className={classes.container}>
			<GlobalHeader hasText={true} isFixed={false} onBackClicked={onClose} />
			<NewSizeHeader />
			<List className={classes.list}>
				{cartItems.map((cartItem, index) => (
					<ListItem key={cartItem.articleId} className={classes.item}>
						<CartNewSizeCell
							previousCartItem={cartItem}
							isLoading={isLoading === cartItem.articleId}
							onItemEdited={(it) => {
								setEditingItem(it);
								setShowEdit(!showEdit);
							}}
						/>
						{index !== Object.keys(cart.items).length - 1 ? <Divider className={classes.divider} /> : null}
					</ListItem>
				))}
			</List>
			{editingItem && stocks ? (
				<ChooseSizeAndQuantity
					open={showEdit}
					onDismiss={() => setShowEdit(false)}
					items={getProduct(editingItem)?.articles || []}
					stocks={stocks}
					label={t("product.reorder_size")}
					onSetItem={() => {
						Events.hit("pick_new_size", {
							model_id: editingItem?.articleId || "unknown"
						});
						onClose();
					}}
				/>
			) : null}
		</div>
	);
};

export default EditSize;
