import React, { useRef, useState, useEffect } from "react";
import classes from "./styles.module.scss";
import lottie from "lottie-web";
import classnames from "classnames";

import Logo from "../../assets/logo.svg";
import loaderData from "./loader.json";

type FullScreenLoaderProps = {
	visible: boolean;
	minDuration?: number;
	onEnded?: () => void;
};

const FullScreenLoader: React.FC<FullScreenLoaderProps> = (props) => {
	const animContainer = useRef<HTMLDivElement>(null);

	const [visible, setVisible] = useState(false);
	const [visibleSince, setVisibleSince] = useState(0);

	useEffect(() => {
		if (props.visible !== visible) {
			if (!visible && props.visible) {
				setVisibleSince(+new Date());
				setVisible(props.visible);
			} else if (visible && !props.visible) {
				setTimeout(() => {
					setVisible(props.visible);
					props.onEnded && props.onEnded();
				}, (props.minDuration || 0) - (+new Date() - visibleSince));
			} else {
				setVisible(props.visible);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.visible]);

	useEffect(() => {
		if (animContainer && animContainer.current) {
			lottie.loadAnimation({
				container: animContainer.current,
				renderer: "svg",
				loop: true,
				autoplay: true,
				animationData: loaderData,
				rendererSettings: { preserveAspectRatio: "xMidYMid slice" }
			});
		}
	}, [animContainer]);

	return (
		<div className={classnames(classes.root, { [classes.visible]: visible })}>
			<div>
				<img src={Logo} alt="Decathlon" />
			</div>
			<div className={classes.animation}>
				<div ref={animContainer} />
			</div>
			<div>{props.children}</div>
		</div>
	);
};
export default FullScreenLoader;
