import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { KeyboardBackspace } from "@material-ui/icons";

import { FullScreenLoader, ProductNotFound } from "../../components";
import { Cart, FullProduct, Stock } from "../../core";
import ProductRepository from "../../core/repositories/ProductRepository";
import StockRepository from "../../core/repositories/StockRepository";
import Product from "./Product";

import classes from "./styles.module.scss";

type ProductPageProps = {
	onClose: () => void;
};

const ProductPage: React.FC<ProductPageProps> = ({ onClose }) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isNotShowroom, setIsNotShowroom] = useState<boolean>(false);
	const [product, setProduct] = useState<FullProduct | undefined>();
	const [fullStocks, setFullStocks] = useState<Array<Stock>>([]);
	const [stocks, setStocks] = useState<Array<Stock> | undefined>();
	const { id: productId } = useParams();

	const { t } = useTranslation();

	useEffect(() => {
		(async () => {
			if (!productId) return;

			// Get product
			try {
				const retrievedProduct = await ProductRepository.getProduct(productId, "ca_en");
				if (retrievedProduct) setProduct(retrievedProduct);

				// get items stock
				const items = retrievedProduct ? retrievedProduct?.product?.articles : [];
				const stocks = await StockRepository.getStock(items?.map((i) => "" + i.articleId));
				const stocksClone = JSON.parse(JSON.stringify(stocks || []));

				setFullStocks(stocksClone);
				if (stocks) setStocks(calcStocksAvailable(stocks));

				// handle UI
				setIsNotShowroom(false);
			} catch {
				setIsNotShowroom(true);
			}
			setIsLoading(false);
		})();
	}, [productId]);

	function onRetry() {
		window.location.reload();
	}

	/**
	 * Calc stocks from API (minus already in cart)
	 * @param stocks
	 * @returns
	 */
	const calcStocksAvailable = (stocks: Array<Stock>) => {
		const cart = Cart.getCart();
		Object.values(cart.items).forEach((item) => {
			stocks = stocks.flatMap((stock) => {
				if (item.articleId === "" + stock.id) {
					stock.qty -= item.quantity;
					return stock;
				} else return stock;
			});
		});
		return stocks.filter((x) => x.qty >= 0);
	};

	return (
		<div className={classes.container}>
			<button className={classes.back} onClick={() => onClose()}>
				<KeyboardBackspace />
			</button>
			<FullScreenLoader visible={isLoading} minDuration={1500}>
				{t("loading")}
			</FullScreenLoader>
			{product ? (
				<Product
					product={product}
					stocks={stocks}
					fullStocks={fullStocks}
					onClose={onClose}
					isNotShowRoom={isNotShowroom}
				/>
			) : !isLoading ? (
				<ProductNotFound onRetry={onRetry} />
			) : null}
		</div>
	);
};

export default React.memo(ProductPage);
