import React, { useState } from "react";
import { GlobalHeader } from "../../components";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Skip from "../../components/Skip";
import { MobileStepper } from "@material-ui/core";
import Events from "../../core/repositories/Events";
import classes from "./styles.module.scss";
import SlideOne from "./Slides/SlideOne";
import SlideTwo from "./Slides/SlideTwo";
import SlideThree from "./Slides/SlideThree";
import SlideFour from "./Slides/SlideFour";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

type TutorialProps = {
	onSkip: () => void;
};

const Tutorial: React.FC<TutorialProps> = ({ onSkip }) => {
	const [index, setIndex] = useState(0);

	return (
		<div className={classes.container}>
			<GlobalHeader hasText={index !== 0} isFixed={false} />
			<div className={classes.slides}>
				<AutoPlaySwipeableViews
					index={index}
					autoplay={true}
					interval={5000}
					onChangeIndex={(index) => {
						if (index === 3) {
							Events.hit("tutorial_end", {});
						}
						setIndex(index);
					}}
				>
					<SlideOne />
					<SlideTwo />
					<SlideThree />
					<SlideFour />
				</AutoPlaySwipeableViews>
			</div>

			<MobileStepper
				variant="dots"
				steps={4}
				position="static"
				activeStep={index}
				className={classes.dots}
				backButton={null}
				nextButton={null}
			/>

			<div className={classes.footer_content}>
				<Skip
					onSkip={() => {
						Events.hit("skip_tutorial", {});
						onSkip();
					}}
				/>
			</div>
		</div>
	);
};

export default React.memo(Tutorial);
