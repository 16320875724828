import React from "react";
import { List, ListItem } from "@material-ui/core";

import Notification from "./Notification";
import { CartState } from "../../../../types";
import { Cart } from "../../../../core";
import { CartHeader } from "../../../../components";
import { useTranslation } from "react-i18next";

type NotificationsProps = {
	onChangeSize: (cartId: string) => void;
};

const Notifications: React.FC<NotificationsProps> = ({ onChangeSize }) => {
	const { t } = useTranslation();
	const cartsInPrep = Cart.getPreviousCarts().filter((c) => [CartState.IN_PREPARATION].indexOf(c.state) >= 0);
	const cartsPickup = Cart.getPreviousCarts().filter((c) => [CartState.READY_TO_PICKUP].indexOf(c.state) >= 0);

	if (!cartsInPrep.length && !cartsPickup.length) {
		return null;
	}

	return (
		<div>
			<List>
				{cartsInPrep
					.sort((a, b) => b.updatedAt - a.updatedAt)
					.map((cart, index) => (
						<ListItem key={cart.id}>
							<Notification index={index} cart={cart} onChangeSize={() => onChangeSize(cart.id)} />
						</ListItem>
					))}
			</List>
			{cartsPickup.length > 0 ? <CartHeader title={t("cart.old_selection")} /> : null}
			<List>
				{cartsPickup
					.sort((a, b) => b.updatedAt - a.updatedAt)
					.map((cart, index) => (
						<ListItem key={cart.id}>
							<Notification index={index} cart={cart} onChangeSize={() => onChangeSize(cart.id)} />
						</ListItem>
					))}
			</List>
		</div>
	);
};

export default Notifications;
