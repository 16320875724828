import store from "../store";
import { UiActions } from "../store/reducers/ui";

class Ui {
	static hideCartTooltip() {
		store.dispatch({
			type: UiActions.SET,
			payload: { cartTooltipShown: false }
		});
	}
}

export default Ui;
