import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useCameraPermission, useInterval } from "../../hooks";
import { PermissionState } from "../../types";
import { default as PermissionStateView } from "./PermissionState";
import CodeReader from "./CodeReader";
import { ScannerConfig } from "../../core";

import classes from "./styles.module.scss";

export type ScannerProps = {
	decode?: boolean;
	onScan: (scanned: string) => void;
};

const Scanner: React.FC<ScannerProps> = ({ onScan, decode }) => {
	const permissionState = useCameraPermission();
	const webcamRef = useRef<HTMLVideoElement>(null);
	const [stream, setStream] = useState<MediaStream>();
	const [codeReader, setCodeReader] = useState<CodeReader>();
	const [decodeIntervalDelay, setDecodeIntervalDelay] = useState<number | null>(null);

	useEffect(() => {
		const codeReader = new CodeReader(ScannerConfig.filters);
		setCodeReader(codeReader);
	}, []);

	useEffect(() => {
		setDecodeIntervalDelay(decode ? 100 : null);
	}, [decode]);

	useEffect(() => {
		if (permissionState === PermissionState.GRANTED && webcamRef && webcamRef.current && !stream) {
			(async () => {
				const deviceConstraints = {
					audio: false,
					video: {
						width: window.innerWidth,
						height: window.innerHeight,
						facingMode: "environment"
					}
				};
				const cameraStream = await navigator.mediaDevices.getUserMedia(deviceConstraints);
				if (webcamRef?.current) {
					webcamRef.current.srcObject = cameraStream;
					codeReader?.setStream(webcamRef.current, cameraStream);
					setStream(cameraStream);
				}
			})();
		}

		return () => {
			stream?.getTracks().forEach((track) => track.stop());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [permissionState, webcamRef, stream]);

	useInterval(async () => {
		const readCode = (await codeReader?.process()) as Record<string, string> | null;
		if (readCode) {
			onScan(readCode.modelCode);
		}
	}, decodeIntervalDelay);

	const { t } = useTranslation();

	return (
		<div className={classes.root}>
			{permissionState === PermissionState.PROMPT ? <PermissionStateView isPrompt={true} /> : null}
			{permissionState === PermissionState.DENIED ? <PermissionStateView isPrompt={false} /> : null}
			{permissionState === PermissionState.GRANTED ? (
				<div>
					<div className={classes.cameraLoading}>{t("camera.image_loading")}</div>
					<video muted ref={webcamRef} playsInline={true} autoPlay={true} className={classes.camera} />
					<div className={classes.maskContainer}>
						<div className={classes.mask} />
					</div>
				</div>
			) : null}
		</div>
	);
};

export default Scanner;
