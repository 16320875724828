import { createAction, createReducer } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";

import { CartsState } from "../states";
import { Cart as CartType, CartItem as CartItemType, CartState as CartStateEnum } from "../../types";

function createEmptyCart(): CartType {
	return {
		id: uuid(),
		state: CartStateEnum.CREATED,
		addedAt: new Date().valueOf(),
		updatedAt: new Date().valueOf(),
		items: {}
	};
}

const currentCart = createEmptyCart();

const initialState: CartsState = {
	currentId: currentCart.id,
	all: {
		[currentCart.id]: currentCart
	}
};

export enum CartsActions {
	SET = "carts/set",
	CREATE_CART = "carts/create",
	SET_CART = "carts/setCart",
	DELETE_CART = "carts/delete",
	ADD_ITEM = "carts/addItem",
	DELETE_ITEMS = "carts/deleteItems",
	SET_ITEM = "carts/setItem",
	SET_CART_STATE = "carts/setState"
}

const set = createAction<Partial<CartsState>>(CartsActions.SET);
const createCart = createAction(CartsActions.CREATE_CART);
const setCart = createAction<Partial<CartType>>(CartsActions.SET_CART);
const deleteCart = createAction<Partial<CartType>>(CartsActions.DELETE_CART);
const addItem = createAction<CartItemType>(CartsActions.ADD_ITEM);
const deleteItems = createAction<Array<Partial<CartItemType>>>(CartsActions.DELETE_ITEMS);
const setItem = createAction<CartItemType>(CartsActions.SET_ITEM);
const setState = createAction<Partial<CartType>>(CartsActions.SET_CART_STATE);

export default createReducer(initialState, (builder) => {
	builder.addCase(set, (state, { payload }) => {
		Object.assign(state, payload);
	});

	builder.addCase(createCart, (state) => {
		const current = createEmptyCart();
		state.all[current.id] = current;
		state.currentId = current.id;
	});

	builder.addCase(setCart, (state, { payload }) => {
		if (payload.id) {
			state.all[payload.id] = Object.assign({}, state.all[payload.id], payload, { updatedAt: new Date().valueOf() });
		}
	});

	builder.addCase(deleteCart, (state, { payload }) => {
		if (payload.id) {
			delete state.all[payload.id];
		}
	});

	builder.addCase(addItem, (state, { payload }) => {
		const current = state.all[state.currentId];
		if (current.items[payload.articleId]) {
			state.all[state.currentId].items[payload.articleId].quantity += payload.quantity || 1;
		} else {
			state.all[state.currentId].items[payload.articleId] = payload;
		}
		state.all[state.currentId].updatedAt = new Date().valueOf();
	});

	builder.addCase(deleteItems, (state, { payload }) => {
		payload.forEach((c) => {
			if (c.articleId && state.all[state.currentId].items[c.articleId]) {
				delete state.all[state.currentId].items[c.articleId];
			}
		});
		state.all[state.currentId].updatedAt = new Date().valueOf();
	});

	builder.addCase(setItem, (state, { payload }) => {
		if (payload.articleId && payload.quantity) {
			state.all[state.currentId].items[payload.articleId] = payload;
		}
		state.all[state.currentId].updatedAt = new Date().valueOf();
	});

	builder.addCase(setState, (state, { payload }) => {
		if (payload.id && payload.state) {
			state.all[payload.id].state = payload.state;
			state.all[payload.id].updatedAt = new Date().valueOf();
		}
	});
});
