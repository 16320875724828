import React, { useState } from "react";
import classes from "./styles.module.scss";
import { CloseButton, Scanner } from "../../components";
import { Snackbar } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";
import { useCameraPermission } from "../../hooks";
import { PermissionState } from "../../types";

type ScanProps = {
	onScan: (scanned: string) => void;
	onBack: () => void;
};

function Alert(props: AlertProps) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Scan: React.FC<ScanProps> = ({ onScan, onBack }) => {
	const [showError, setShowError] = useState<boolean>(false);

	const cameraPermissionState = useCameraPermission();

	const handleClose = (_?: React.SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setShowError(false);
	};

	const { t } = useTranslation();

	return (
		<div>
			<CloseButton className={classes.button} onClose={() => onBack()} />
			<Scanner onScan={(scanned) => onScan(scanned)} decode={true} />
			{cameraPermissionState === PermissionState.GRANTED ? (
				<div className={classes.help}>{t("scan.help_qr")}</div>
			) : null}
			<Snackbar open={showError} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="error">
					{t("scan.qr_read_error")}
				</Alert>
			</Snackbar>
		</div>
	);
};

export default Scan;
