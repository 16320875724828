import { useRef, useEffect, FunctionComponent } from "react";
import classes from "./styles.module.scss";
import lottie from "lottie-web";

import { Grid } from "@material-ui/core";

import animationData from "./not-found.json";
import { useTranslation } from "react-i18next";
import YellowButton from "../Buttons/YellowButton";

type ProductNotFoundProps = {
	onRetry?: () => void;
};

const ProductNotFound: FunctionComponent<ProductNotFoundProps> = ({ onRetry }) => {
	const animContainer = useRef<HTMLDivElement>(null);
	const { t } = useTranslation();

	useEffect(() => {
		if (animContainer && animContainer.current) {
			lottie.loadAnimation({
				container: animContainer.current,
				renderer: "svg",
				loop: true,
				autoplay: true,
				animationData,
				rendererSettings: { preserveAspectRatio: "xMidYMid slice" }
			});
		}
	}, [animContainer]);

	return (
		<Grid className={classes.root} container direction="column" justifyContent="center" alignItems="center">
			<Grid item className={classes.animation}>
				<div ref={animContainer} />
			</Grid>
			<Grid item className={classes.text}>
				{t("product.not_found_title")}
			</Grid>
			{onRetry ? (
				<Grid item>
					<YellowButton text={t("product.retry")} onAction={onRetry} className={classes.button} />
				</Grid>
			) : null}
		</Grid>
	);
};
export default ProductNotFound;
