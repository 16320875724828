import jsQR from "jsqr";

export type CodeReaderFilter = {
	reg: RegExp;
	indexes: Record<string, number>;
};

export default class CodeReader {
	stream?: MediaStream;
	video?: HTMLVideoElement;
	isProcessing = false;
	filters?: CodeReaderFilter[];

	constructor(filters?: CodeReaderFilter[]) {
		this.filters = filters;
	}

	setStream(video: HTMLVideoElement, stream: MediaStream) {
		this.stream = stream;
		this.video = video;
	}

	async process(): Promise<string | Record<string, string> | null> {
		let text: string | null = null;

		const { stream, video, filters } = this;

		const shouldProcess = stream && stream.active && video && video.readyState > 1;

		if (shouldProcess && video) {
			const { videoWidth: width, videoHeight: height } = video;
			if (width && height) {
				const canvas = document.createElement("canvas");
				canvas.width = width;
				canvas.height = height;

				const context = canvas.getContext("2d");
				if (context) {
					context.drawImage(video, 0, 0, width, height, 0, 0, width, height);

					// getImageData is 3 to 4 times faster than toDataURL AND zxing misses some QR
					const image = context.getImageData(0, 0, width, height);
					const code = jsQR(image.data, width, height);
					text = code?.data || null;
				}
			}
		}

		if (filters && text) {
			const [res] = filters
				.map((p) => {
					const { reg, indexes } = p;
					const match = (text as string).match(reg);
					if (match && match.length >= Math.max(...Object.values(indexes)) + 1) {
						const result = {} as Record<string, string>;
						Object.keys(indexes).forEach((k) => {
							result[k] = match[indexes[k]];
						});
						return result;
					}
					return null;
				})
				.filter((e) => !!e);

			if (res) {
				return res;
			}
			return null;
		}
		return text;
	}
}
