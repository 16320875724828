import { useState, useEffect, FunctionComponent } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import EditSize from "../EditSize";
import { FullScreenLoader } from "../../components";
import { useTranslation } from "react-i18next";
import { usePersistentStores, useUpdateCartsState } from "../../hooks";

import Tutorial from "../Tutorial";
import Scan from "../Scan";
import ProductPage from "../ProductPage";
import LastOrdersPage from "../LastOrders";
import Cart from "../Cart";
import { sendPageViewEvent } from "../../shared/events/events.utils";

import styles from "./main.module.scss";

const Main: FunctionComponent = () => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const navigate = useNavigate();

	useEffect(() => {
		sendPageViewEvent(window.location.pathname);
	}, []);

	usePersistentStores();
	useUpdateCartsState();

	useEffect(() => {
		(async () => {
			//load things
			setIsLoading(false);
		})();
	}, []);

	const goTo = (route: string) => {
		navigate(route);
	};

	const { t } = useTranslation();

	return (
		<div className={styles.content}>
			{isLoading ? (
				<FullScreenLoader visible={isLoading} minDuration={1000}>
					{t("loading")}
				</FullScreenLoader>
			) : (
				<div className={styles.page}>
					<Routes>
						<Route path="/" element={<Tutorial onSkip={() => goTo("/cart")} />} />
						<Route
							path="/scan"
							element={<Scan onBack={() => goTo("/cart")} onScan={(productId) => goTo(`/product/${productId}`)} />}
						/>
						<Route path="/editSize/:id" element={<EditSize onClose={() => goTo("/cart")} />} />
						<Route path="/product/:id" element={<ProductPage onClose={() => goTo("/cart")} />} />
						<Route
							path="/cart"
							element={
								<Cart
									onScan={() => goTo("/scan")}
									onProductClicked={(id: string) => goTo(`/product/${id}`)}
									newSize={(cartId: string) => goTo(`/editSize/${cartId}`)}
									onSubscribe={() => goTo("/subscribe")}
								/>
							}
						/>
						<Route path="/last-orders" element={<LastOrdersPage />} />
						<Route
							path="/subscribe"
							element={() => {
								window.location.href = `${process.env.REACT_APP_MEMBERSHIP_ENDPOINT}`;
								return null;
							}}
						/>
					</Routes>
				</div>
			)}
		</div>
	);
};

export default Main;
