import React, { useEffect, useState } from "react";
import classes from "./styles.module.scss";

import {
	GlobalHeader,
	Spacer,
	SelectionHeader,
	YellowButton,
	GradientButton,
	CartHeader,
	Membership,
	CartCell,
	BottomSheet,
	NotAvailableItems,
	WarningDeleteDialog,
	FullScreenLoader
} from "../../components";
import { Divider, List, ListItem, Snackbar } from "@material-ui/core";
import { Stock } from "../../core";
import OrderRepository from "../../core/repositories/OrderRepository";
import Notifications from "./Sections/Notifications";
import EmptyCart from "./Sections/EmptyCart";
import { Alert } from "@material-ui/lab";
import { CartConfirmed, ChooseSizeAndQuantity, ConfirmCart } from "../../dialogs";
import { useTranslation } from "react-i18next";
import StockRepository from "../../core/repositories/StockRepository";
import { Cache, Cart as CoreCart } from "../../core";
import Events from "../../core/repositories/Events";

import { CartState, CartItem } from "../../types";

type CartProps = {
	onScan: () => void;
	onProductClicked: (id: string) => void;
	newSize: (cartId: string) => void;
	onSubscribe: () => void;
};

const Cart: React.FC<CartProps> = ({ onScan, onProductClicked, newSize, onSubscribe }) => {
	const [showConfirm, setShowConfirm] = useState<boolean>(false);
	const [showConfirmed, setShowConfirmed] = useState<boolean>(false);
	const [showEdit, setShowEdit] = useState<boolean>(false);
	const [notAvailableItems, setNotAvailableItems] = useState<Array<CartItem>>([]);
	const [showNotAvailable, setShowNotAvailable] = useState<boolean>(false);
	const [editingItem, setEditingItem] = useState<CartItem | undefined>();
	const [deletedItem, setDeletedItem] = useState<CartItem | undefined>();
	const [showWarningDialog, setShowWarningDialog] = useState<boolean>(false);
	const [clickTimestamps, setClickTimestamps] = useState<Array<number>>([]);
	const [showClearCacheSnackBar, setShowClearCacheSnackBar] = useState<boolean>(false);
	const [stocks, setStocks] = useState<Array<Stock> | undefined>();
	const [isValidating, setIsValidating] = useState<boolean>(false);
	const [isEditing, setIsEditing] = useState<string | undefined>(undefined);
	const { t } = useTranslation();

	const cart = CoreCart.getCart();
	const previousCarts = CoreCart.getPreviousCarts();
	const items = CoreCart.getItems();

	useEffect(() => {
		(async () => {
			if (editingItem) {
				setStocks(undefined);
				setIsEditing(editingItem.articleId);
				try {
					const product = Cache.getProductFromArticleId(editingItem.articleId);
					const articlesId = product?.articles.map((a) => "" + a.articleId) || [];
					const stocks = await StockRepository.getStock(articlesId);
					setStocks(stocks);
					setShowEdit(!showEdit);
				} catch {
					console.error("error while getting stocks information");
				}
				setIsEditing(undefined);
			}
		})();
	}, [editingItem]);

	/** SECRET CLICK ON HEADER */

	const onHeaderClick = () => {
		const newTimestamp = new Date().getTime();
		if (clickTimestamps.length > 1 && newTimestamp - clickTimestamps[clickTimestamps.length - 1] > 5 * 1000)
			setClickTimestamps([]);
		clickTimestamps.push(newTimestamp);
		if (clickTimestamps.length === 5) setShowClearCacheSnackBar(true);
		if (clickTimestamps.length >= 10) {
			setClickTimestamps([]);
			localStorage.clear();
			window.location.reload();
		}
	};

	function hideSnackBar() {
		setShowClearCacheSnackBar(false);
	}

	const handleDeleteItem = (doDelete: boolean) => {
		if (doDelete && deletedItem !== undefined) {
			CoreCart.deleteItem(deletedItem);
		}
		setDeletedItem(undefined);
		setShowWarningDialog(false);
	};

	const animateConfirm = () => {
		setShowConfirm(!showConfirm);
	};

	const handleValidateCart = () => {
		setShowConfirm(false);
		if (CoreCart.getItems().length === 0) {
			return;
		}

		setIsValidating(true);
		// Don't use cart because it might not be updated when called after notAvailableItems removal
		const currentCart = CoreCart.getCart();
		OrderRepository.createOrder("Hermione Granger", currentCart)
			.then((order) => {
				if (order) {
					CoreCart.updateCart({
						preparationId: order.autostore_order.order_name,
						state: CartState.IN_PREPARATION
					});
					CoreCart.createCart();
				}
				setShowConfirmed(true);
			})
			.catch((errorData) => {
				if (errorData?.statusCode === 504) {
					setShowConfirmed(true);
					return;
				}
				// TODO: handle error differently depending
				// if it's a stock error or something else
				// and avoid displaying the "validate my picking anyway" in all the cases
				const notAvailableArticleId: Array<string> = errorData.error_items;
				const cartItems = CoreCart.getItems();
				const notAvailableItems: Array<CartItem> = (notAvailableArticleId || [])
					.map((articleId) => {
						return cartItems.filter((item) => item.articleId === articleId)[0];
					})
					.filter((it) => !!it);

				setNotAvailableItems(notAvailableItems);

				setShowNotAvailable(true);
			})
			.finally(() => {
				setIsValidating(false);
			});
	};

	const handleValidatePartialCart = (doContinue: boolean) => {
		if (doContinue) {
			CoreCart.deleteItems(notAvailableItems);
			handleValidateCart();
			// TODO: handle empty cart, display something to the user like "cart is now empty, nothing happened"
		}
		setShowNotAvailable(false);
	};

	const handleConfirmDeleteItem = (item: CartItem) => {
		setDeletedItem(item);
		setShowWarningDialog(true);
	};

	const handleEditItem = (item: CartItem) => {
		setEditingItem(item);
	};

	const handleSelectItem = (item: CartItem) => {
		onProductClicked(Cache.getProductId(item.articleId) || "");
	};

	const renderCart = () => {
		if (cart) {
			return (
				<List className={classes.list}>
					{previousCarts.length && items.length ? <CartHeader title={t("cart.new_selection")} /> : null}
					{items.map((item, index) => (
						<ListItem key={item.articleId} className={classes.item}>
							<CartCell
								cart={cart}
								articleId={item.articleId}
								isEditing={isEditing === item.articleId}
								onItemDeleted={handleConfirmDeleteItem}
								onItemEdited={handleEditItem}
								onItemClicked={handleSelectItem}
							/>
							{index !== items.length - 1 ? <Divider className={classes.divider} /> : null}
						</ListItem>
					))}
				</List>
			);
		} else return null;
	};

	const handleDismissEditItem = () => {
		setEditingItem(undefined);
		setShowEdit(false);
	};

	const handleSetItem = () => {
		if (editingItem) {
			Events.hit("pick_another_size", {
				product_id: Cache.getProductId(editingItem.articleId)
			});
		}
		handleDismissEditItem();
	};

	return (
		<div className={classes.container}>
			<FullScreenLoader visible={isValidating} minDuration={500}>
				{t("loading")}
			</FullScreenLoader>
			<GlobalHeader hasText={true} isFixed={false} onHeaderClicked={onHeaderClick} />
			{items.length !== 0 || previousCarts.length ? (
				<div>
					<SelectionHeader />
					{renderCart()}
					<Notifications
						onChangeSize={(cartId) => {
							Events.hit("need_another_size", {});
							newSize(cartId);
						}}
					/>
					{previousCarts.length ? (
						<Membership
							onSubscribe={() => {
								Events.hit("new_member", {});
								onSubscribe();
							}}
						/>
					) : null}

					{items.length > 0 ? (
						<div className={classes.validateButtonContainer}>
							<YellowButton
								className={classes.validateButton}
								text={t("cart.validate_pickup")}
								onAction={() => animateConfirm()}
							/>
						</div>
					) : null}
					<GradientButton
						className={classes.scan_button}
						text={t("cart.scan_button_another")}
						onAction={() => onScan()}
					/>
				</div>
			) : (
				<EmptyCart onScan={onScan} />
			)}
			<Spacer vertical={20} />

			<Snackbar open={showClearCacheSnackBar} autoHideDuration={5000} onClose={hideSnackBar}>
				<Alert severity="warning">{t("cart.cache")}</Alert>
			</Snackbar>

			<BottomSheet open={showConfirm} onDismiss={() => setShowConfirm(false)}>
				<ConfirmCart
					onClose={() => setShowConfirm(false)}
					onConfirm={() => {
						Events.hit("confirm_cart", {});
						handleValidateCart();
					}}
				/>
			</BottomSheet>

			<BottomSheet open={showConfirmed} onDismiss={() => setShowConfirmed(false)}>
				<CartConfirmed onClose={() => setShowConfirm(false)} onConfirmed={() => setShowConfirmed(false)} />
			</BottomSheet>

			{editingItem && stocks ? (
				<ChooseSizeAndQuantity
					open={showEdit}
					onDismiss={handleDismissEditItem}
					items={Cache.getProductFromArticleId(editingItem.articleId)?.articles || []}
					stocks={stocks}
					editingArticleId={editingItem.articleId}
					onSetItem={handleSetItem}
				/>
			) : null}

			<BottomSheet open={showNotAvailable} onDismiss={() => setShowNotAvailable(false)}>
				<NotAvailableItems cart={notAvailableItems} onContinue={handleValidatePartialCart} />
			</BottomSheet>

			<WarningDeleteDialog open={showWarningDialog} handleClose={handleDeleteItem} />
		</div>
	);
};

export default Cart;
