import React, { MouseEvent } from "react";
import { useTranslation } from "react-i18next";

import CloseIcon from "../../../assets/close.svg";

import styles from "./close-button.module.scss";

type CloseButtonProps = {
	onClose: () => void;
	className?: string;
};

const CloseButton: React.FC<CloseButtonProps> = ({ className, onClose }) => {
	const { t } = useTranslation();

	const handleClose = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		onClose();
	};

	return (
		<div className={className}>
			<button className={styles.btn} onClick={handleClose} aria-label={t("Close")}>
				<img className={styles.icon} src={CloseIcon} alt={t("Close")} />
			</button>
		</div>
	);
};

export default React.memo(CloseButton);
