import React from "react";

import styles from "./yellow-button.module.scss";

type YellowButtonProps = {
	className?: string;
	text: string;
	onAction: () => void;
};

const YellowButton: React.FC<YellowButtonProps> = ({ className, text, onAction }) => {
	return (
		<div className={className}>
			<button className={styles.btn} onClick={() => onAction()}>
				{text}
			</button>
		</div>
	);
};

export default React.memo(YellowButton);
