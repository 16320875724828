import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Typography } from "@material-ui/core";

const useStyles = makeStyles(({ spacing }) => ({
	container: {
		maxWidth: "100%",
		display: "flex",
		flexDirection: "row",
		color: "#000",
		alignItems: "center"
	},
	title: {
		fontWeight: "bold",
		fontFamily: "300",
		fontSize: "15pt",
		padding: spacing(),
		marginLeft: spacing(2)
	},
	divider: {
		height: "1px",
		width: "120px",
		backgroundColor: "#000"
	}
}));

type CartHeaderProps = {
	title: string;
};

const CartHeader: React.FC<CartHeaderProps> = ({ title }) => {
	const classes = useStyles();

	return (
		<div className={classes.container}>
			<Typography className={classes.title}>{title}</Typography>
			<Divider className={classes.divider} />
		</div>
	);
};

export default CartHeader;
