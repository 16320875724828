import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { YellowButton } from "../../components";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(({ spacing }) => ({
	container: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		padding: spacing(2)
	},
	catchline: {
		fontSize: "16pt",
		fontWeight: "bold",
		transform: "skewY(-10deg)"
	},
	explanation: {
		width: "100%",
		textAlign: "center",
		padding: spacing(0, 1)
	},
	button: {
		marginBottom: spacing(3),
		marginTop: spacing(3)
	},
	close: {
		marginLeft: "auto",
		alignSelf: "right"
	},
	horizontalContent: {
		display: "flex",
		flexDirection: "row",
		padding: spacing(2),
		width: "100%",
		justifyContent: "center",
		boxSizing: "border-box",
		marginTop: spacing(3)
	},
	cancelButton: {
		marginRight: spacing(1)
	},
	confirmButton: {
		marginLeft: spacing(1)
	}
}));

type ConfirmCartProps = {
	onConfirm: () => void;
	onClose: () => void;
};

const ConfirmCart: React.FC<ConfirmCartProps> = ({ onConfirm, onClose }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.container}>
			<Typography className={classes.catchline}>{t("cart.confirm.did_you_select")}</Typography>
			<Typography className={classes.catchline}>{t("cart.confirm.all_the_products")}</Typography>
			<Typography className={classes.catchline}>{t("cart.confirm.you_need")}</Typography>
			<div className={classes.horizontalContent}>
				<Button variant="outlined" className={classes.cancelButton} onClick={onClose}>
					{t("cart.cancel")}
				</Button>
				<YellowButton className={classes.confirmButton} text={t("cart.confirm.action")} onAction={onConfirm} />
			</div>
			<Typography className={classes.explanation}>{t("cart.confirm.if_you_confirm")}</Typography>
			<Typography className={classes.explanation}>{t("cart.confirm.will_be_available")}</Typography>
		</div>
	);
};

export default React.memo(ConfirmCart);
