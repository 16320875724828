import { FunctionComponent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Typography } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { ReviewsType } from "../../core";

const useStyles = makeStyles(({ spacing }) => ({
	container: {
		display: "flex",
		flexDirection: "column",
		width: "90%",
		padding: spacing(2)
	},
	title: {
		fontFamily: "500",
		fontSize: "12pt"
	},
	value: {
		fontFamily: "400",
		fontSize: "9pt",
		width: "100%"
	},
	info: {
		fontFamily: "500",
		fontWeight: "bold",
		fontSize: "13pt"
	},
	divider: {
		margin: spacing()
	},
	close: {
		marginLeft: "auto",
		alignSelf: "right"
	}
}));

type ReviewsProps = {
	reviews: ReviewsType;
};

const Reviews: FunctionComponent<ReviewsProps> = ({ reviews }) => {
	const classes = useStyles();
	const comments = reviews.reviews;

	return (
		<div className={classes.container}>
			{comments.map((comment, index) => (
				<div key={comment.id}>
					<Rating name="read-only" value={comment.rating} size="small" readOnly />
					<Typography className={classes.title}>{comment.title}</Typography>
					<Typography>{comment.username}</Typography>
					<Typography className={classes.value}>{comment.message}</Typography>
					{index < comments.length - 1 ? <Divider className={classes.divider} /> : null}
				</div>
			))}
		</div>
	);
};

export default Reviews;
