import React from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import styles from "./skip.module.scss";

type SkipProps = {
	onSkip: () => void;
};

const Skip: React.FC<SkipProps> = ({ onSkip }) => {
	const { t } = useTranslation();

	return (
		<div className={styles.wrapper}>
			<button className={styles.btnWrapper} onClick={() => onSkip()}>
				<Typography className={styles.skip}>{t("tutorial.skip")}</Typography>
			</button>
		</div>
	);
};

export default React.memo(Skip);
