import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";

import QRCodeIcon from "../../../assets/qrcode.svg";

import styles from "./gradient-button.module.scss";

type GradientButtonProps = {
	text: string;
	onAction: () => void;
	className?: string;
};

const GradientButton: FunctionComponent<GradientButtonProps> = ({ className, text, onAction }) => {
	const { t } = useTranslation();

	return (
		<div className={className}>
			<button className={styles.btn} onClick={onAction}>
				<img className={styles.qrCode} alt={t("QR Code")} src={QRCodeIcon} />
				<Typography className={styles.text}>{text}</Typography>
			</button>
		</div>
	);
};

export default GradientButton;
