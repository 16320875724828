import { FunctionComponent } from "react";
import { Typography } from "@material-ui/core";
import classes from "./styles.module.scss";
import { useTranslation } from "react-i18next";

type PriceProps = {
	value?: number;
	className?: string;
};

const Price: FunctionComponent<PriceProps> = ({ value, className }) => {
	const { t } = useTranslation();

	return (
		<div className={className + " " + classes.content}>
			<Typography className={classes.price}>{value ? t("cart.currency", { amount: value }) : "-"}</Typography>
		</div>
	);
};

export default Price;
