import { FunctionComponent } from "react";
import classes from "./CartContainers.module.scss";
import { useTranslation } from "react-i18next";

type ContainersProps = {
	containers?: string[];
};

const CartContainers: FunctionComponent<ContainersProps> = ({ containers }) => {
	const { t } = useTranslation();

	if (!(containers && containers.length)) {
		return null;
	}

	return (
		<div className={classes.root}>
			{t("cart.containers", { count: containers.length })}{" "}
			{containers.map((c) => (
				<span key={c} className={classes.container}>
					#{c}
				</span>
			))}
		</div>
	);
};

export default CartContainers;
