/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from "react";
import { RootState } from "src/store/states";
import { useAppDispatch, useAppSelector } from "../store";

export default function usePersistentStores() {
	const stores = useAppSelector<RootState, RootState>((stores) => stores);
	const dispatch = useAppDispatch();

	useEffect(() => {
		Object.keys(stores).forEach((storeName) => {
			const savedContent = JSON.parse(localStorage.getItem(`cg.${storeName}`) || "null");
			if (savedContent) {
				dispatch({ type: `${storeName}/set`, payload: savedContent });
			}
		});
	}, []);

	useEffect(() => {
		Object.keys(stores).forEach((storeName) => {
			const storeContent = (stores as any)[storeName];
			if (storeContent) {
				localStorage.setItem(`cg.${storeName}`, JSON.stringify((stores as any)[storeName]));
			}
		});
	}, [stores]);
}
