import { FunctionComponent, useEffect, useState } from "react";
import classes from "./styles.module.scss";
import { BottomSheet, YellowButton } from "../../components";
import { Typography } from "@material-ui/core";
import { Cart as CoreCart, Cart, Item, Stock } from "../../core";
import { useTranslation } from "react-i18next";
import ItemAndStock from "../../core/types/ItemAndStock";
import Price from "../../components/Price";
import QuantityPicker from "./QuantityPicker";
import SizePicker from "./SizePicker";

type ChooseSizeAndQuantityProps = {
	open: boolean;
	items: Array<Item>;
	stocks: Array<Stock>;
	onDismiss: () => void;

	editingArticleId?: string;
	label?: string;
	onSetItem: () => void;
};

/*
 * RULES:
 * - if on product page/reorder (editSize), stock must be real stock minus cart
 * - if editing the item (ie. in cart), current article stock must be the real stock and other article stock must be real stock minus cart
 * ADD TO CART:
 * - if on product page/reorder, item must be added (if not in cart, add to the list else add selected quantity to cart)
 * - if editing the item (ie. in cart), item should replace the current one by selected one
 */

const ChooseSizeAndQuantity: FunctionComponent<ChooseSizeAndQuantityProps> = (props) => {
	const { open, items, stocks, onDismiss, editingArticleId, label, onSetItem } = props;

	const [itemsAvailable, setItemsAvailable] = useState<Array<ItemAndStock>>([]);
	const [articleId, setArticleId] = useState<string>();
	const [quantity, setQuantity] = useState<number>(1);
	const [maxQuantity, setMaxQuantity] = useState<number>(1);
	const { t } = useTranslation();

	useEffect(() => {
		if (!open) {
			setArticleId(undefined);
			setQuantity(1);
		}
	}, [open]);

	useEffect(() => {
		if (items && stocks) {
			const itemsAvailable = items.map((item) => {
				let stock = stocks?.find((y) => "" + y.id === "" + item.articleId);
				if (!stock) {
					stock = { id: item.articleId, qty: 0 };
				}

				const cartItem = Cart.getCart().items[item.articleId];
				if (editingArticleId !== "" + item.articleId && cartItem) {
					stock.qty -= cartItem.quantity;
				}
				stock.qty = Math.max(0, stock.qty);
				return { item, stock };
			});

			setItemsAvailable(itemsAvailable);

			const articleId = editingArticleId ? editingArticleId : "" + itemsAvailable[0]?.item.articleId;
			setArticleId(articleId);
		}
	}, [items, stocks, editingArticleId]);

	useEffect(() => {
		if (articleId) {
			let quantity = 1;
			if (editingArticleId && articleId === editingArticleId) {
				const currentItem = Cart.getItems().find((i) => i.articleId === editingArticleId);
				quantity = currentItem?.quantity || 1;
			}
			setQuantity(quantity);

			const max = itemsAvailable.find((y) => "" + y.item.articleId === articleId)?.stock.qty || 0;
			setMaxQuantity(max);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [articleId, itemsAvailable]);

	const handleOnDimiss = () => {
		onDismiss();
	};

	const handleSelect = () => {
		if (articleId) {
			const item = { articleId, quantity };
			if (editingArticleId) {
				const oldItem = { articleId: editingArticleId, quantity: 0 };
				CoreCart.replaceItem(oldItem, item);
			} else {
				CoreCart.addItem(item);
			}
			onSetItem();
		}
	};

	const price = items.filter((i) => "" + i.articleId !== articleId)[0]?.priceForFront.finalPrice;

	const index = itemsAvailable && articleId ? itemsAvailable.findIndex((x) => "" + x?.item.articleId === articleId) : 0;

	let buttonLabel = label;
	if (!buttonLabel) {
		buttonLabel = editingArticleId ? t("product.validate_update") : t("product.add_to_pickup");
	}

	const footer = <YellowButton className={classes.add} text={buttonLabel || ""} onAction={handleSelect} />;

	return (
		<BottomSheet open={open} onDismiss={handleOnDimiss} footer={footer}>
			<div className={classes.container}>
				{price ? <Price className={classes.price} value={price} /> : null}
				<Typography className={classes.sub_title}>{t("cart.quantity")}</Typography>
				<QuantityPicker
					initQuantity={quantity}
					maxQuantity={maxQuantity}
					className={classes.quantity_picker}
					onQuantitySelected={(quantity) => setQuantity(quantity)}
				/>
				<Typography className={classes.stock_warning}>{t("cart.stock_warning", { count: maxQuantity })}</Typography>
				<Typography className={classes.sub_title}>Size</Typography>
				{itemsAvailable && itemsAvailable.length ? (
					<SizePicker
						initIndex={index}
						sizes={itemsAvailable}
						onSizeSelected={(i) => setArticleId("" + i.item.articleId)}
					/>
				) : null}
			</div>
		</BottomSheet>
	);
};

export default ChooseSizeAndQuantity;
