/* eslint-disable @typescript-eslint/no-explicit-any */
class Events {
	static pageView(page: string) {
		this.hit("page_view", { page_location: page });
	}

	static hit(eventType: string, data: any = {}) {
		if (!(window as any).gtag) {
			console.log(`Should event "${eventType}", "${JSON.stringify(data)}"`);
			return;
		}

		if (!data.non_interaction) {
			data.non_interaction = true;
		}
		(window as any).gtag("event", eventType, data);
	}
}

export default Events;
