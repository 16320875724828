import classes from "./styles.module.scss";
import TutorialProductIcon from "../../../../assets/tutorial_product.svg";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const SlideTwo: React.FC = () => {
	const { t } = useTranslation();

	return (
		<div className={classes.slide}>
			<img className={classes.icon_product} src={TutorialProductIcon} alt="Product" />
			<Typography className={classes.step_two}>1</Typography>
			<Typography className={classes.big_text}>{t("tutorial.scan_your")}</Typography>
			<Typography className={classes.big_text2}>{t("tutorial.products")}</Typography>
		</div>
	);
};

export default SlideTwo;
