import classes from "./styles.module.scss";
import HelpIcon from "../../../../assets/help.svg";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const SlideFour: React.FC = () => {
	const { t } = useTranslation();

	return (
		<div className={classes.slide}>
			<img className={classes.icon_help} src={HelpIcon} alt="Help" />
			<Typography className={classes.big_text}>{t("tutorial.need_help")}</Typography>
			<div className={classes.explanation_content}>
				<Typography className={classes.explanation}>{t("tutorial.here_for_you")}</Typography>
			</div>
		</div>
	);
};

export default SlideFour;
