import { configureStore } from "@reduxjs/toolkit";
import reducer from "./reducers";
import { createDispatchHook, createSelectorHook } from "react-redux";

const store = configureStore({
	reducer,
	devTools: process.env.REACT_APP_ENVIRONMENT !== "prod"
});

export default store;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = createDispatchHook<AppDispatch>();
export const useAppSelector = createSelectorHook();
