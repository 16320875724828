import React, { useState } from "react";
import FullProduct from "../../../core/types/FullProduct";
import ProductPictures from "../ProductPictures";
import ProductContent from "../ProductContent";
import ProductFooter from "../ProductFooter";
import { Advantages, ChooseSizeAndQuantity, ProductInfos, ReviewsDialog } from "../../../dialogs";
import { BottomSheet } from "../../../components";
import Stock from "../../../core/types/stock/Stock";
import Events from "../../../core/repositories/Events";
import { Cache } from "../../../core";
import ProductVariant from "../ProductVariant/ProductVariant.component";

import classes from "./product.module.scss";

type ProductProps = {
	product: FullProduct;
	stocks?: Stock[];
	fullStocks: Stock[];
	isNotShowRoom: boolean;
	onClose: () => void;
	className?: string;
};

const Product: React.FC<ProductProps> = ({ product, stocks, fullStocks, isNotShowRoom, onClose, className }) => {
	const [index, setIndex] = useState<number>(0);
	const [showValidate, setShowValidate] = useState<boolean>(false);
	const [showProductInfos, setShowProductInfos] = useState<boolean>(false);
	const [showAdvantages, setShowAdvantages] = useState<boolean>(false);
	const [showReviews, setShowReviews] = useState<boolean>(false);

	if (!product) return null;

	//DATA
	const pictures = product.product.images;
	const items = product.product.articles;
	const informationConcept = product.product.description.informationConcept;
	const benefits = product.product.description.benefits;
	const reviews = product.reviews;

	const handleSetItem = () => {
		Cache.addProduct(product.product);
		onClose();
	};

	return (
		<div className={className + " " + classes.root}>
			<ProductPictures pictures={pictures} index={index} onChangeIndex={setIndex} />
			<ProductVariant variants={product.variants} />
			<ProductContent
				product={product}
				showProductInfos={() => {
					Events.hit("technical_informations", {
						model_id: product.product.modelId
					});
					setShowProductInfos(true);
				}}
				showAdvantages={() => {
					Events.hit("benefits", { model_id: product.product.modelId });
					setShowAdvantages(true);
				}}
				showReviews={() => {
					Events.hit("reviews", { model_id: product.product.modelId });
					setShowReviews(true);
				}}
				className={classes.productInfos}
			/>
			<ProductFooter
				items={items}
				stocks={stocks}
				isNotShowroom={isNotShowRoom}
				onValidate={() => setShowValidate(true)}
			/>
			<ChooseSizeAndQuantity
				items={items}
				stocks={fullStocks}
				open={showValidate}
				onDismiss={() => setShowValidate(false)}
				onSetItem={handleSetItem}
			/>
			{informationConcept && informationConcept.technicalInformation ? (
				<BottomSheet open={showProductInfos} onDismiss={() => setShowProductInfos(false)}>
					<ProductInfos infos={informationConcept.technicalInformation} />
				</BottomSheet>
			) : null}
			{benefits ? (
				<BottomSheet open={showAdvantages} onDismiss={() => setShowAdvantages(false)}>
					<Advantages advantages={benefits} />
				</BottomSheet>
			) : null}

			{reviews ? (
				<BottomSheet open={showReviews} onDismiss={() => setShowReviews(false)}>
					<ReviewsDialog reviews={reviews} />
				</BottomSheet>
			) : null}
		</div>
	);
};

export default React.memo(Product);
