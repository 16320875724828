import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEn from "./en.json";

const resources = {
	en: { translation: translationEn }
};

// TODO: to be cleaner, should be stored somewhere else
const forceLanguage = "en";

i18n.use(initReactI18next).init({
	resources,
	lng: forceLanguage || localStorage.getItem("current_language") || navigator.language.substr(0, 2),
	fallbackLng: ["en"],
	keySeparator: ".",
	interpolation: {
		escapeValue: false
	}
});

export { forceLanguage as currentLanguage };
export default i18n;
