import { FunctionComponent } from "react";
import Picture from "../../../core/types/product/Picture";
import { MobileStepper } from "@material-ui/core";
import { autoPlay } from "react-swipeable-views-utils";
import SwipeableViews from "react-swipeable-views";
import { useTranslation } from "react-i18next";
import { NoImagePlaceholder } from "../../../components";

import styles from "./product-picture.module.scss";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

type ProductPicturesProps = {
	pictures: Array<Picture>;
	index: number;
	onChangeIndex: (index: number) => void;
	className?: string;
};

const ProductPictures: FunctionComponent<ProductPicturesProps> = ({
	pictures,
	index,
	onChangeIndex,
	className = ""
}) => {
	const { t } = useTranslation();

	return (
		<div className={className + " " + styles.root}>
			<AutoPlaySwipeableViews index={index} autoplay={true} interval={5000} onChangeIndex={onChangeIndex}>
				{pictures && pictures.length ? (
					pictures.map((packshot) => (
						<div className={styles.slide + " " + (pictures.length === 1 ? "uniq" : "")} key={packshot.id}>
							<img
								className={styles.picture}
								src={packshot.url + "?f=400x400"}
								alt={t("Product")}
								width={400}
								height={400}
							/>
						</div>
					))
				) : (
					<div className={styles.slide + " " + styles.uniq}>
						<NoImagePlaceholder />
					</div>
				)}
			</AutoPlaySwipeableViews>
			{pictures && pictures.length > 1 ? (
				<MobileStepper
					variant="dots"
					steps={pictures.length}
					position="static"
					activeStep={index}
					className={styles.dots}
					backButton={null}
					nextButton={null}
				/>
			) : null}
		</div>
	);
};

export default ProductPictures;
