import React from "react";

import styles from "./red-button.module.scss";

type RedButtonProps = {
	text: string;
	className?: string;
	onAction: () => void;
};

const RedButton: React.FC<RedButtonProps> = ({ className, text, onAction }) => {
	return (
		<div className={className}>
			<button className={styles.btn} onClick={() => onAction()}>
				{text}
			</button>
		</div>
	);
};

export default React.memo(RedButton);
