import classes from "./styles.module.scss";
import TutorialListPhone from "../../../../assets/tutorial_list_phone.png";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const SlideThree: React.FC = () => {
	const { t } = useTranslation();

	return (
		<div className={classes.slide}>
			<img className={classes.icon_product} src={TutorialListPhone} alt="Phone" />
			<Typography className={classes.step_three}>2</Typography>
			<Typography className={classes.big_text}>{t("tutorial.create")}</Typography>
			<Typography className={classes.big_text2}>{t("tutorial.your_list")}</Typography>
		</div>
	);
};

export default SlideThree;
