import { createAction, createReducer } from "@reduxjs/toolkit";

import { CacheState } from "../states";
import ProductType from "../../core/types/product/ProductType";

const initialState: CacheState = {
	products: {},
	items: {}
};

export enum CacheActions {
	SET = "cache/set",
	ADD_PRODUCT = "cache/addProduct"
}

const set = createAction<Partial<CacheState>>(CacheActions.SET);
const addProduct = createAction<ProductType>(CacheActions.ADD_PRODUCT);

export default createReducer(initialState, (builder) => {
	builder.addCase(set, (state, { payload }) => {
		Object.assign(state, payload);
	});

	builder.addCase(addProduct, (state, { payload }) => {
		state.products["" + payload.modelId] = payload;
		payload.articles.forEach((a) => {
			state.items["" + a.articleId] = "" + payload.modelId;
		});
	});
});
