import { makeStyles } from "@material-ui/core/styles";
import { Divider, Typography } from "@material-ui/core";
import { Benefit } from "../../core";

const useStyles = makeStyles(({ spacing }) => ({
	container: {
		display: "flex",
		flexDirection: "column",
		width: "90%",
		padding: spacing(2)
	},
	title: {
		fontFamily: "500",
		fontWeight: "bold",
		fontSize: "11pt"
	},
	value: {
		fontFamily: "400",
		fontSize: "9pt",
		width: "100%"
	},
	info: {
		fontFamily: "500",
		fontWeight: "bold",
		fontSize: "13pt"
	},
	divider: {
		margin: spacing()
	},
	close: {
		marginLeft: "auto",
		alignSelf: "right"
	}
}));

type AdvantagesProps = {
	advantages: Benefit[];
};

const Advantages: React.FC<AdvantagesProps> = ({ advantages }) => {
	const classes = useStyles();

	return (
		<div className={classes.container}>
			{advantages.map((advantage) => (
				<div key={advantage.name}>
					<Typography className={classes.title}>{advantage.name}</Typography>
					<Typography className={classes.value}>{advantage.description}</Typography>
					<Divider className={classes.divider} />
				</div>
			))}
		</div>
	);
};

export default Advantages;
