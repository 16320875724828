import React, { useRef, useEffect } from "react";
import classes from "./PermissionState.module.scss";
import lottie from "lottie-web";

import { Grid } from "@material-ui/core";

import infoData from "./info.json";
import errorData from "./error.json";
import { useTranslation } from "react-i18next";

type PermissionStateProps = {
	isPrompt: boolean;
};

const PermissionState: React.FC<PermissionStateProps> = ({ isPrompt }) => {
	const animContainer = useRef<HTMLDivElement>(null);
	const { t } = useTranslation();

	const data = isPrompt ? infoData : errorData;
	const text = t(isPrompt ? "permission.camera_prompt_info" : "permission.camera_access_denied");

	useEffect(() => {
		if (animContainer && animContainer.current) {
			lottie.loadAnimation({
				container: animContainer.current,
				renderer: "svg",
				loop: true,
				autoplay: true,
				animationData: data,
				rendererSettings: { preserveAspectRatio: "xMidYMid slice" }
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [animContainer]);

	return (
		<Grid container direction="column" justifyContent="center" alignItems="center">
			<Grid item className={classes.animation}>
				<div ref={animContainer} />
			</Grid>
			<Grid item className={classes.text}>
				{text}
			</Grid>
		</Grid>
	);
};
export default PermissionState;
