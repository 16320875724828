import React from "react";
import { Typography } from "@material-ui/core";
import { Price } from "../../../components";
import Rating from "@material-ui/lab/Rating";
import FullProduct from "../../../core/types/FullProduct";
import TechnicalInformations from "./TechnicalInformations";

import classes from "./product-content.module.scss";

type ProductContentProps = {
	product?: FullProduct;
	showReviews: () => void;
	showProductInfos: () => void;
	showAdvantages: () => void;
	className?: string;
};

const ProductContent: React.FC<ProductContentProps> = ({
	product,
	showReviews,
	showProductInfos,
	showAdvantages,
	className
}) => {
	//DATA
	if (!product) return null;

	const price = product.product.priceForFront?.finalPrice;
	const brand = product.product.brand;
	const productName = product.product.description.productName;
	const catchLine = product.product.description.catchLineInternet;
	const productId = product.product.modelId;
	const reviews = product.product.review;

	function handleShowReviews() {
		if (reviews && reviews.count && product?.reviews?.reviews?.length) {
			showReviews();
		}
	}

	return (
		<div className={className}>
			<div className={classes.product_infos_child}>
				<Price className={classes.price} value={price} />
				<div className={classes.infos}>
					<Typography className={classes.brand}>{brand}</Typography>
					<Typography className={classes.label}>{productName}</Typography>
					{reviews ? (
						<button
							className={classes.ratingContent}
							onClick={handleShowReviews}
							disabled={!(reviews.count && product?.reviews?.reviews?.length)}
						>
							<Rating className={classes.rating} name="read-only" value={reviews.averageRating} size="small" readOnly />
							<Typography className={classes.ratingCount}>({reviews.count})</Typography>
						</button>
					) : null}
					<Typography className={classes.catchLine}>{catchLine}</Typography>
					<Typography className={classes.reference}>{productId}</Typography>
				</div>
				<TechnicalInformations
					description={product.product.description}
					showAdvantages={showAdvantages}
					showProductInfos={showProductInfos}
				/>
				<div className={classes.footer_space} />
			</div>
		</div>
	);
};

export default React.memo(ProductContent);
