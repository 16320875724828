import { FunctionComponent } from "react";

import CartStateReady from "./CartStateReady";
import CartStateInPreparation from "./CartStateInPreparation";
import { Grid } from "@material-ui/core";
import { CartState } from "../../../../../types";
import { Cart } from "./../../../../../types";

type CardNotificationProps = {
	index: number;
	cart: Cart;
	onChangeSize: () => void;
};

const Notification: FunctionComponent<CardNotificationProps> = ({ cart, onChangeSize }) => {
	return (
		<Grid container direction="column">
			<Grid item>
				{cart.state === CartState.READY_TO_PICKUP ? (
					<CartStateReady cart={cart} onChangeSize={onChangeSize} />
				) : (
					<CartStateInPreparation />
				)}
			</Grid>
		</Grid>
	);
};

export default Notification;
