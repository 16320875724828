import OrderRepository from "../core/repositories/OrderRepository";
import { AutoStoreState, CartState } from "../types";
import { Cart } from "../core";
import useInterval from "./useInterval";

export default function useUpdateCartsState() {
	const CHECK_STATE_INTERVAL = 15 * 1000;
	const SET_PICKED_UP_AFTER = 2 * 60 * 60 * 1000;

	useInterval(
		async () => {
			const currentTimestamp = new Date().valueOf();
			const carts = Cart.getCarts();
			carts.forEach(async (cart) => {
				if ([CartState.CREATED, CartState.IN_PREPARATION].indexOf(cart.state) >= 0 && cart.preparationId) {
					const { status: preparationState, cntnr_name } = await OrderRepository.getOrderState(cart.preparationId);
					let cartState = CartState.IN_PREPARATION;
					if (preparationState === AutoStoreState.READY) {
						cartState = CartState.READY_TO_PICKUP;
					}
					Cart.updateCart(
						{
							containers: cntnr_name,
							state: cartState
						},
						cart.id
					);
				} else if (
					[CartState.READY_TO_PICKUP, CartState.PICKED_UP].indexOf(cart.state) >= 0 &&
					currentTimestamp - cart.updatedAt >= SET_PICKED_UP_AFTER
				) {
					Cart.deleteCart(cart.id);
				}
			});
		},
		CHECK_STATE_INTERVAL,
		true
	);
}
