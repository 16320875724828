import React, { ReactElement } from "react";
import classes from "./styles.module.scss";
import { BottomSheet as SpringBottomSheet } from "react-spring-bottom-sheet";

import "react-spring-bottom-sheet/dist/style.css";

type AdvantagesProps = {
	open: boolean;
	onDismiss: () => void;
	title?: string;
	footer?: ReactElement;
};

const BottomSheet: React.FC<AdvantagesProps> = ({ open, onDismiss, title, footer, children }) => {
	return (
		<SpringBottomSheet
			className={classes.root}
			open={open}
			onDismiss={() => onDismiss()}
			maxHeight={window.screen.availHeight * 0.8}
			header={title ? <h3 className={classes.title}>{title}</h3> : undefined}
			footer={footer}
		>
			{children}
		</SpringBottomSheet>
	);
};

export default BottomSheet;
