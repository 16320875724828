import React from "react";

type SpacerProps = {
	vertical?: number;
	horizontal?: number;
};

const Spacer: React.FC<SpacerProps> = ({ vertical, horizontal }) => {
	return (
		<div
			style={{
				height: (vertical || 0) * 8 + "px",
				width: (horizontal || 0) * 8 + "px"
			}}
		/>
	);
};

export default Spacer;
