import store from "../store";
import { ProductType } from "./types/product";
import { CacheActions } from "../store/reducers/cache";

class Cache {
	static addProduct(product: ProductType) {
		store.dispatch({ type: CacheActions.ADD_PRODUCT, payload: product });
	}

	static getProduct(productId?: string): ProductType | null {
		const { cache } = store.getState();
		if (productId && cache.products[productId]) {
			return cache.products[productId];
		}
		return null;
	}

	static getProductId(articleId: string): string | undefined {
		const { cache } = store.getState();
		return cache.items[articleId];
	}

	static getProductFromArticleId(articleId: string): ProductType | null {
		return this.getProduct(this.getProductId(articleId));
	}
}

export default Cache;
