import React from "react";
import { ProductVariant as ProductVariantData } from "src/core/types/product/product-variant.type";

import Placeholder from "../../../assets/product-image-placeholder.jpg";

import styles from "./product-variant.module.scss";

type Props = {
	variants?: ProductVariantData[];
};

const ProductVariant = ({ variants }: Props) => {
	if (!variants || variants.length === 0) return null;

	return (
		<div className={styles.wrapper} data-testid="ProductVariant_wrapper">
			<div className={styles.variantsWrapper}>
				{variants.map((variant) => {
					if (!variant || variant.lifeCycle === 8) {
						return null;
					}
					const imageUrl = variant.images?.[0]?.url || Placeholder;
					return (
						<a href={`/product/${variant.modelId}`} className={styles.variant} key={variant.modelId}>
							<img src={`${imageUrl}?f=72x72`} alt={variant.description?.productName} width={72} height={72} />
						</a>
					);
				})}
			</div>
		</div>
	);
};

export default ProductVariant;
