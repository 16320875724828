import { FunctionComponent } from "react";
import { Grid } from "@material-ui/core";

import Logo from "../../../assets/logo.svg";
import Close from "../../../assets/close.svg";

import classes from "./global-header.module.scss";

type HeaderProps = {
	isFixed: boolean;
	hasText: boolean;
	onHeaderClicked?: () => void;
	onBackClicked?: () => void;
};

const Header: FunctionComponent<HeaderProps> = ({ isFixed, hasText, onHeaderClicked, onBackClicked }) => {
	return (
		<Grid
			container
			className={isFixed ? classes.rootFixed : classes.root}
			alignItems="center"
			justifyContent="space-between"
		>
			{hasText ? (
				<Grid item>
					<button onClick={onHeaderClicked} className={classes.btnLogo}>
						<img src={Logo} alt="Decathlon" className={classes.logo} />
					</button>
				</Grid>
			) : null}
			{onBackClicked ? (
				<Grid item>
					<button onClick={onBackClicked} className={classes.backBtn} aria-label="close size edition">
						<img src={Close} alt="Back" />
					</button>
				</Grid>
			) : null}
		</Grid>
	);
};

export default Header;
